const authProviderConfig = {
  dev: {
    authorizeUri: 'https://silta.sandbox.signicat.com/auth/open/connect/authorize',
    redirectUri: 'http://localhost:3000/redirect',
    clientId: 'sandbox-little-lizard-798',
    state: 'dev'
  },
  test: {
    authorizeUri: 'test',
    redirectUri: 'test',
    clientId: 'test',
    state: 'test'
  },
  staging: {
    authorizeUri: 'https://silta.sandbox.signicat.com/auth/open/connect/authorize',
    redirectUri: 'https://esimo-staging.silta.fi/redirect',
    clientId: 'sandbox-little-lizard-798',
    state: 'staging'
  },
  production: {
    authorizeUri: 'https://silta.app.signicat.com/auth/open/connect/authorize',
    redirectUri: 'https://esimo.silta.fi/redirect',
    clientId: 'prod-soggy-board-559',
    state: 'production'
  }
}

const authProviderDemoConfig = {
  authorizeUri: 'https://silta.sandbox.signicat.com/auth/open/connect/authorize',
  clientId: 'sandbox-little-lizard-798',
  state: 'demo'
}

const env = process.env.REACT_APP_AUTH_PROVIDER_ENV as 'dev' | 'staging' | 'production' | undefined
export default env !== undefined && process.env.REACT_APP_ENV !== 'test'
  ? authProviderConfig[env]
  : (process.env.REACT_APP_ENV === 'test' ? authProviderConfig.test : undefined)

export { authProviderDemoConfig }
