import React from 'react'
import ReactDOM from 'react-dom/client'
import 'react-date-picker/dist/DatePicker.css'
import 'react-calendar/dist/Calendar.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import './index.scss'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import Landing from './routes/Landing'
import { ResourceProvider } from './contexts/ResourceContext'
import { AuthProvider } from './contexts/AuthContext'
import PrivateRoute from './components/PrivateRoute'
import { LocaleProvider } from './contexts/LocaleContext'
import TaxInfo from './routes/TaxInfo'
import UserInfo from './routes/UserInfo'
import Absences from './routes/Absences'
import Salaries from './routes/Salaries'
import SalariesYear from './routes/SalariesYear'
import Salary from './routes/Salary'
import Terms from './routes/Terms'
import Redirect from './routes/Redirect'
import { AnalyticsProvider } from './contexts/AnalyticsContext'

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
)

root.render(
  <React.StrictMode>
    <AuthProvider>
      <ResourceProvider>
        <LocaleProvider>
          <AnalyticsProvider>
            <BrowserRouter>
              <Routes>
                <Route path='/' element={<App />}>
                  <Route index element={<Landing />} />
                  <Route path='redirect' element={<Redirect />} />
                  <Route path='agree_terms' element={<Terms agreeable />} />
                  <Route element={<PrivateRoute />}>
                    <Route path='user_info' element={<UserInfo />} />
                    <Route path='salaries' element={<Salaries />} />
                    <Route path='salaries/:year' element={<SalariesYear />} />
                    <Route path='salaries/:companyId/:employmentId/:payday' element={<Salary />} />
                    <Route path='salaries/:year/:companyId/:employmentId/:payday' element={<Salary />} />
                    <Route path='tax_info' element={<TaxInfo />} />
                    <Route path='absences' element={<Absences />} />
                    <Route path='terms' element={<Terms />} />
                  </Route>
                </Route>
              </Routes>
            </BrowserRouter>
          </AnalyticsProvider>
        </LocaleProvider>
      </ResourceProvider>
    </AuthProvider>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
