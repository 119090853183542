import { ReactElement } from 'react'
import Expandable from './Expandable'
import SalaryDetailsRow from './SalaryDetailsRow'
import InfoRow from './InfoRow'
import useLocalization from '../hooks/useLocalization'
import useFormatter from '../hooks/useFormatter'
import useSalaries from '../hooks/useSalaries'

interface SubItem {
  Value: string | null
  Description: string | null
  DescriptionEN?: string | null
  DescriptionSV?: string | null
  Quantity?: string | null
  UnitPrice?: string | null
  Start?: string | null
  End?: string | null
}

interface Item {
  Description: string | null
  Sum: string | null
  SubItems: SubItem[]
}

interface Props {
  id: string
  payday: string
  sicknessPayment: string | null
  title: string
  total: string | null
  items: Item[] | null
}

const SalaryDetailsSection = ({ id, payday, sicknessPayment, title, total, items }: Props): ReactElement => {
  const { tSalaryDescription, tSalaryExplanation } = useLocalization()
  const { formatCurrency } = useFormatter()
  const { config } = useSalaries()

  const explanation = (row: { Description: string | null }): string | null => {
    return tSalaryExplanation(
      row,
      payday,
      sicknessPayment !== null ? formatCurrency(sicknessPayment) : null,
      config
    )
  }

  const sectionTitle = (title: string, total: string | null): ReactElement => (
    <div className='sectionTitle'>
      <div className='title'>
        {title}
      </div>
      <div className='total'>
        {formatCurrency(total)}
      </div>
    </div>
  )

  const subItems = (item: Item): SubItem[] => {
    return [...item.SubItems].sort((a, b) => {
      if (
        a.Start !== undefined &&
        a.Start !== null &&
        a.Start !== '' &&
        b.Start !== undefined &&
        b.Start !== null &&
        b.Start !== ''
      ) {
        return new Date(a.Start).valueOf() - new Date(b.Start).valueOf()
      } else {
        return 1
      }
    })
  }

  return (
    <div className='SalaryDetailsSection' id={id}>
      {sectionTitle(title, total)}
      {Array.isArray(items) && items.map((item, index) =>
        <Expandable
          disable={explanation(item) === null && item.SubItems.length === 0}
          title={tSalaryDescription(item)}
          subtitle={formatCurrency(item.Sum)}
          id={`${id}Section${index}`}
          key={`${id}Section${index}`}
        >
          {subItems(item).map((subItem, subIndex) => (
            <SalaryDetailsRow
              id={`${id}Section${index}Row${subIndex}`}
              key={`${id}Section${index}Row${subIndex}`}
              item={subItem}
            />
          ))}
          {explanation(item) !== null && (
            <InfoRow
              id={`${id}Section${index}Explanation`}
              label={explanation(item)}
              variant='smallPrint'
            />
          )}
        </Expandable>
      )}
    </div>
  )
}
export default SalaryDetailsSection
