import { useContext, useState } from 'react'
import useApi from './useApi'
import { AuthContext } from '../contexts/AuthContext'
import versions from '../config/versions'
import authProviderConfig from '../config/authProviderConfig'

interface ReturnProps {
  postRegistration: (deviceId: string) => Promise<string | null>
  postOidcAuth: (code: string) => Promise<string | null>
  ping: () => Promise<void>
  signOut: () => void
  loading: boolean
  error: string | null
}

const useAuth = (): ReturnProps => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<string | null>(null)
  const { oidcAuthInProgress, setOidcAuthStatus, signOut: authSignOut, deviceId } = useContext(AuthContext)
  const { get, post, delete_, endpoints } = useApi()

  // Use this to get new token for keeping the session alive
  const ping = async (): Promise<void> => {
    setLoading(true)
    try {
      await get(endpoints.pingPath)
    } catch (error) {
      // Do nothing
    }
    setLoading(false)
  }

  const postRegistration = async (deviceId: string): Promise<string | null> => {
    setLoading(true)
    const data = {
      deviceId,
      pin: Math.random().toString(36).slice(2, 13)
    }
    const headers = {
      'X-Client-Version': `eSimo-${versions.app ?? 0}`
    }
    try {
      const responseData = await post(endpoints.newRegisterPath, JSON.stringify(data), headers)
      setLoading(false)
      return responseData.token
    } catch (error) {
      setError(error instanceof Error ? error.message : String(error))
      setLoading(false)
      return null
    }
  }

  const postOidcAuth = async (code: string): Promise<string | null> => {
    // Prevent double auth (causes problems w back-end)
    if (oidcAuthInProgress()) {
      console.log('postOidcAuth already in progress')
      return null
    }
    setOidcAuthStatus(true)
    setLoading(true)
    const data = {
      redirectUri: authProviderConfig?.redirectUri ?? '',
      code
    }
    try {
      const responseData = await post(
        endpoints.oidcPath,
        JSON.stringify(data)
      ) as { deviceId: string }
      setLoading(false)
      setOidcAuthStatus(false)
      return responseData.deviceId
    } catch (error) {
      setError(error instanceof Error ? error.message : String(error))
      setLoading(false)
      setOidcAuthStatus(false)
      return null
    }
  }

  const signOut = async (): Promise<void> => {
    setLoading(true)
    try {
      if (deviceId !== null) {
        await delete_(endpoints.signOutPath.replace('{deviceId}', deviceId))
      }
      setLoading(false)
    } catch (error) {
      setError(error instanceof Error ? error.message : String(error))
      setLoading(false)
    }
    authSignOut()
  }

  return {
    ping,
    postRegistration,
    postOidcAuth,
    signOut,
    loading,
    error
  }
}

export default useAuth
