import { useContext, useState } from 'react'
import useApi from './useApi'
import { ResourceContext } from '../contexts/ResourceContext'
import UserApps from '../types/UserApps'
import Account from '../types/Account'
import TermsOfServiceStatus from '../types/TermsOfServiceStatus'
import versions from '../config/versions'

interface ReturnProps {
  getUserApps: (account: Account) => Promise<void>
  getTermsOfServiceStatus: () => Promise<number | null>
  postTermsOfServiceAgreement: () => Promise<boolean>
  termsOfServiceStatus: TermsOfServiceStatus | null
  loading: boolean
  error: string | null
  userApps: {[companyId: string]: UserApps}
}

const useUser = (): ReturnProps => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<string | null>(null)
  const { get, post, endpoints } = useApi()
  const {
    userApps,
    setUserApps,
    setTermsOfServiceStatus,
    termsOfServiceStatus
  } = useContext(ResourceContext)

  const postTermsOfServiceAgreement = async (): Promise<boolean> => {
    setLoading(true)
    setError(null)
    try {
      const data = {
        service: 'web',
        version: versions.termsOfService
      }
      await post(endpoints.termsOfServiceAgreementPath, JSON.stringify(data))
      setLoading(false)
      return true
    } catch (error) {
      setError(error instanceof Error ? error.message : String(error))
      setLoading(false)
      return false
    }
  }

  // Returns the number of agreed web version or null if not agreed
  const getTermsOfServiceStatus = async (): Promise<number | null> => {
    setLoading(true)
    try {
      const data = await get(endpoints.termsOfServiceStatusPath) as TermsOfServiceStatus
      setTermsOfServiceStatus(data)
      setLoading(false)
      return data.termsOfWebServiceVersion
    } catch (error) {
      setError(error instanceof Error ? error.message : String(error))
      setLoading(false)
      return null
    }
  }

  /*
  * App1: Medical
  * App2: Send tax card (not in use)
  * App3: Web UI
  */
  const getUserApps = async (account: Account): Promise<void> => {
    const employments = account?.AccountInfo?.Employment
    if (!Array.isArray(employments)) {
      // Account was not loaded or no employments defined for user
      return
    }
    const companyIds = employments.map(e => e.CompanyId).filter(id => id !== null) as string[]
    // Already loaded?
    if (companyIds.every(id => Object.keys(userApps).includes(id))) {
      return
    }
    setLoading(true)
    const apps = Object.assign({}, userApps)
    for (const companyId of employments.map(e => e.CompanyId)) {
      if (companyId === null || apps[companyId] !== undefined) {
        continue
      }
      try {
        const data = await get(endpoints.userAppsPath.replace('{companyId}', companyId))
        apps[companyId] = data.apps as UserApps
      } catch (error) {
        setError(error instanceof Error ? error.message : String(error))
      }
    }
    setUserApps(apps)
    setLoading(false)
  }

  return {
    termsOfServiceStatus,
    getUserApps,
    getTermsOfServiceStatus,
    postTermsOfServiceAgreement,
    loading,
    error,
    userApps
  }
}

export default useUser
