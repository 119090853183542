import React, { ReactElement, useEffect, useState } from 'react'
import Form from 'react-bootstrap/Form'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import useLocalization from '../hooks/useLocalization'
import InfoRow from './InfoRow'
import FormInput from './FormInput'
import { Employment } from '../types/Account'
import FormSelect from './FormSelect'
import FormDatePicker from './FormDatePicker'
import { NewAbsence, AbsenceErrors } from '../types/Absence'
import useValidator from '../hooks/useValidator'
import FormCheckbox from './FormCheckbox'
import useAbsences from '../hooks/useAbsences'
import SubmitButton from './SubmitButton'
import Notification from './Notification'
import useForm from '../hooks/useForm'
import useAnalytics from '../hooks/useAnalytics'

interface Props {
  show: boolean
  onClose: () => void
  onSuccess: () => void
  employments: Employment[]
}

const AbsenceModal = ({ show, onClose, onSuccess, employments }: Props): ReactElement => {
  const { validateAbsence } = useValidator()
  const [validationErrors, setValidationErrors] = useState<AbsenceErrors>({
    StartDate: null,
    EndDate: null,
    ICD: null,
    GivenDate: null,
    CompanyId: null,
    TermsAccepted: null,
    Attachment: null
  })
  const { form, resetForm, handleDateChange, handleInputChange } = useForm<NewAbsence>({
    StartDate: null,
    EndDate: null,
    ICD: null,
    GivenDate: null,
    CompanyId: null,
    TermsAccepted: false,
    AttachmentBase64: null,
    AttachmentFilename: null
  })
  const { t } = useLocalization()
  const { postAbsence, loading, error } = useAbsences()
  const { logAbsencePost } = useAnalytics()
  const [postError, setPostError] = useState(false)
  const [submitted, setSubmitted] = useState(false)

  useEffect(() => {
    if (!show) {
      setPostError(false)
      resetForm()
      setValidationErrors({
        StartDate: null,
        EndDate: null,
        ICD: null,
        GivenDate: null,
        CompanyId: null,
        TermsAccepted: null,
        Attachment: null
      })
    }
  }, [show])

  useEffect(() => {
    if (submitted) {
      const errors = validateAbsence(form)
      setValidationErrors(errors)
    }
  }, [submitted, form])

  const submit = async (): Promise<void> => {
    setSubmitted(true)
    setPostError(false)
    const errors = validateAbsence(form)
    setValidationErrors(errors)
    if (Object.values(errors).every(v => v === null)) {
      if (await postAbsence(form)) {
        logAbsencePost()
        onSuccess()
      } else {
        setPostError(true)
      }
    }
  }

  return (
    <Modal show={show} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>{t('newMedicalCertification.title')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p id='absenceModalDescription'>{t('newMedicalCertification.description')}</p>
        <Form noValidate>
          <InfoRow
            variant='title'
            label={t('newMedicalCertification.sickLeave')}
          />
          <FormDatePicker
            id='AbsenceModalStartDate'
            error={validationErrors.StartDate}
            name='StartDate'
            value={form.StartDate}
            label={t('newMedicalCertification.startDate')}
            onChange={handleDateChange}
            maxDate={form.EndDate ?? form.GivenDate}
          />
          <FormDatePicker
            id='AbsenceModalEndDate'
            error={validationErrors.EndDate}
            name='EndDate'
            value={form.EndDate}
            label={t('newMedicalCertification.endDate')}
            onChange={handleDateChange}
            minDate={form.StartDate}
          />
          <FormInput
            id='AbsenceModalICD'
            horizontal
            error={validationErrors.ICD}
            name='ICD'
            value={form.ICD ?? ''}
            label={t('newMedicalCertification.icdCode')}
            type='text'
            onChange={handleInputChange}
            placeholder={t('newMedicalCertification.setIcd')}
          />
          <FormDatePicker
            id='AbsenceModalGivenDate'
            error={validationErrors.GivenDate}
            name='GivenDate'
            value={form.GivenDate}
            label={t('newMedicalCertification.givenDate')}
            onChange={handleDateChange}
            maxDate={new Date()}
          />
          <InfoRow
            variant='titleWithMargin'
            label={t('newMedicalCertification.certification')}
          />
          <FormInput
            id='AbsenceModalAttachment'
            error={validationErrors.Attachment}
            name='Attachment'
            onChange={handleInputChange}
            type='file'
          />
          <InfoRow
            variant='titleWithMargin'
            label={t('newMedicalCertification.employmentInfo')}
          />
          <FormSelect
            id='AbsenceModalCompanyId'
            error={validationErrors.CompanyId}
            name='CompanyId'
            onChange={handleInputChange}
            value={form.CompanyId}
            placeholder={t('newMedicalCertification.selectEmployment')}
            options={employments.map(e => [e.CompanyId ?? '', e.CompanyName ?? ''])}
          />
          <br />
          <FormCheckbox
            id='AbsenceModalTermsAccepted'
            name='TermsAccepted'
            label={t('newMedicalCertification.terms')}
            onChange={handleInputChange}
            checked={form.TermsAccepted}
            error={validationErrors.TermsAccepted}
          />
        </Form>
        {postError && (
          <Notification variant='danger'>
            {error}
          </Notification>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant='secondary' onClick={onClose}>
          {t('newMedicalCertification.cancel')}
        </Button>
        <SubmitButton
          loading={loading}
          label={t('newMedicalCertification.send')}
          onClick={submit}
        />
      </Modal.Footer>
    </Modal>
  )
}
export default AbsenceModal
