import React, { ReactElement } from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'

interface Props {
  name: string
  label?: string
  placeholder?: string
  onChange: (event: React.ChangeEvent<HTMLSelectElement>) => void
  value: string | null
  options: Array<[string, string]>
  error: string | null
  id?: string
}

const FormSelect = ({ id, error, options, name, value, label, placeholder, onChange }: Props): ReactElement => {
  const selectElement = (): ReactElement => (
    <>
      <Form.Select
        isInvalid={error !== null}
        name={name}
        value={value ?? ''}
        onChange={onChange}
      >
        <option value=''>{placeholder}</option>
        {options.map((item, index) => (
          <option value={item[0]} key={`option-${name}-${index}`}>
            {item[1]}
          </option>
        ))}
      </Form.Select>
      <Form.Control.Feedback type='invalid'>
        {error}
      </Form.Control.Feedback>
    </>
  )

  return (
    <Row id={id}>
      {label !== undefined && (
        <>
          <Form.Label column xs={5}>
            {label}
          </Form.Label>
          <Col xs={7}>
            {selectElement()}
          </Col>
        </>
      )}
      {label === undefined && <Col>{selectElement()}</Col>}
    </Row>
  )
}
export default FormSelect
