import { AbsenceErrors, NewAbsence } from '../types/Absence'
import useLocalization from './useLocalization'
import { SalaryContactErrors, SalaryContactProps } from '../types/SalaryContact'

interface ReturnProps {
  validateAbsence: (absence: NewAbsence) => AbsenceErrors
  validateSalaryContact: (data: SalaryContactProps) => SalaryContactErrors
}

const useValidator = (): ReturnProps => {
  const { t } = useLocalization()

  const validateAbsence = (absence: NewAbsence): AbsenceErrors => {
    const icdRegex = /^([A-Z]|[a-z]|[0-9]|\.|){6}$/
    const filenameParts = absence.AttachmentFilename?.split('.')
    const fileExt: string = Array.isArray(filenameParts) && filenameParts.length > 1
      ? filenameParts[filenameParts.length - 1]
      : ''
    const datesOk = absence.EndDate !== null &&
      absence.StartDate !== null &&
      absence.StartDate.valueOf() <= absence.EndDate.valueOf()
    return {
      StartDate: datesOk
        ? null
        : t('newMedicalCertification.validationFailedTitle'),
      EndDate: datesOk
        ? null
        : t('newMedicalCertification.validationFailedTitle'),
      ICD: icdRegex.test(absence.ICD ?? '')
        ? null
        : t('newMedicalCertification.checkICDCode'),
      GivenDate: absence.GivenDate !== null && (absence.GivenDate?.valueOf() ?? 0) <= Date.now()
        ? null
        : t('newMedicalCertification.checkGivenDate'),
      Attachment: absence.AttachmentFilename === null
        ? t('newMedicalCertification.pictureMissing')
        : ['jpeg', 'jpg', 'png', 'pdf'].includes(fileExt)
            ? null
            : t('newMedicalCertification.pictureFormatInvalid'),
      CompanyId: isPresent(absence.CompanyId)
        ? null
        : t('newMedicalCertification.noCompany'),
      TermsAccepted: absence.TermsAccepted
        ? null
        : t('newMedicalCertification.termsNotAccepted')
    }
  }

  const validateSalaryContact = (data: SalaryContactProps): SalaryContactErrors => {
    return {
      CompanyName: isPresent(data.CompanyName)
        ? null
        : '',
      Email: isValidEmail(data.Email)
        ? null
        : t('mainSalary.invalidEmail')
    }
  }

  const isValidEmail = (email: string | null): boolean => {
    if (email === null || email.length < 1) {
      return false
    }
    const regex = /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/
    return regex.test(email.toLowerCase().trim())
  }

  const isPresent = (value: null | string): boolean => (
    value !== null && value !== ''
  )

  return ({
    validateAbsence,
    validateSalaryContact
  })
}
export default useValidator
