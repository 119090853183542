import React, { ReactElement, useContext, useEffect, useRef, useState } from 'react'
import { AuthContext } from '../contexts/AuthContext'
import apiConfig from '../config/apiConfig'
import Modal from 'react-bootstrap/Modal'
import useLocalization from '../hooks/useLocalization'
import { ResourceContext } from '../contexts/ResourceContext'
import useAuth from '../hooks/useAuth'
import { useNavigate } from 'react-router-dom'
import session from '../config/session'
import SubmitButton from './SubmitButton'

const SessionTimeWatcher = (): ReactElement => {
  const { resetResources } = useContext(ResourceContext)
  const { signOut, ping, loading } = useAuth()
  const { lastRequestTime, isAuth, refreshRequestTime } = useContext(AuthContext)
  const { t } = useLocalization()
  const intervalRef = useRef<number | null>(null)
  const lastPingRef = useRef<number | null>(null)
  const [showDialog, setShowDialog] = useState(false)
  const [timeLeft, setTimeLeft] = useState(0)
  const navigate = useNavigate()

  useEffect(() => {
    start()
    return () => {
      if (intervalRef.current !== null) {
        window.clearInterval(intervalRef.current)
        intervalRef.current = null
      }
    }
  }, [])

  useEffect(() => {
    start()
  }, [isAuth])

  const start = (): void => {
    if (isAuth && intervalRef.current === null) {
      intervalRef.current = window.setInterval(() => checkSessionTime(), 1000)
    } else if (!isAuth && intervalRef.current !== null) {
      window.clearInterval(intervalRef.current)
      intervalRef.current = null
      lastPingRef.current = null
    }
  }

  const pingIfDue = (): void => {
    if (lastPingRef.current === null) {
      return
    }
    const delta = Date.now() - lastPingRef.current
    if (delta > apiConfig.tokenRefreshInterval * 1000) {
      pingServer()
    }
  }

  const checkSessionTime = (): void => {
    const reqTime = lastRequestTime()
    if (reqTime === null) {
      return
    } else if (lastPingRef.current === null) {
      lastPingRef.current = reqTime
    }
    pingIfDue()
    const delta = Date.now() - reqTime
    const threshold = session.dialogShowTimeSeconds * 1000
    const timeLimit = session.idleTimeLimitMinutes * 60000
    if (delta > timeLimit - threshold) {
      if (delta > timeLimit) {
        void handleSignOut()
      } else {
        setShowDialog(true)
        setTimeLeft(Math.round((threshold - (delta - (timeLimit - threshold))) / 1000))
      }
    } else {
      setShowDialog(false)
    }
  }

  const handleSignOut = async (): Promise<void> => {
    resetResources()
    await signOut()
    setShowDialog(false)
    navigate('/', { state: { authFail: true } })
  }

  const handleContinue = (): void => {
    refreshRequestTime()
    pingServer()
  }

  const pingServer = (): void => {
    lastPingRef.current = Date.now()
    void ping()
  }

  return (
    <Modal show={showDialog} onHide={() => !loading && handleContinue()}>
      <Modal.Header closeButton>
        <Modal.Title>{t('sessionTimeWatcher.title')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{t('sessionTimeWatcher.content').replace('{seconds}', `${timeLeft}`)}</Modal.Body>
      <Modal.Footer>
        <SubmitButton
          variant='secondary'
          onClick={handleSignOut}
          label={t('sessionTimeWatcher.signOut')}
          disabled={loading}
        />
        <SubmitButton
          variant='primary'
          onClick={handleContinue}
          label={t('sessionTimeWatcher.continue')}
          loading={loading}
        />
      </Modal.Footer>
    </Modal>
  )
}

export default SessionTimeWatcher
