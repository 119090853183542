import React, { ReactElement } from 'react'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import useLocalization from '../hooks/useLocalization'
import useAuthProvider from '../hooks/useAuthProvider'

interface Props {
  show: boolean
  onHide: () => void
}

const StartDemoModal = ({ show, onHide }: Props): ReactElement => {
  const { linkToAuthMethod } = useAuthProvider()
  const { t, tList } = useLocalization()

  const handleStart = (): void => {
  }

  const formatContent = (content: string): ReactElement => {
    const parts = content.split('DEMOUSER1')
    if (parts.length === 2) {
      return <p>{parts[0]}<code>DEMOUSER1</code>{parts[1]}</p>
    } else {
      return <p>{content}</p>
    }
  }

  return (
    <Modal show={show} onHide={onHide} className='StartDemoModal'>
      <Modal.Header closeButton>
        <Modal.Title>{t('selectMethod.welcomeHeader')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          <strong>
            {t('selectMethod.testingHeader')}
          </strong>
        </p>
        <ol>
          {tList<{ [content: string]: string }>('selectMethod.guide').map((item, index) => (
            <li key={`start-demo-guide-${index}`}>
              {formatContent(item.content)}
            </li>
          ))}
        </ol>
      </Modal.Body>
      <Modal.Footer>
        <Button variant='secondary' onClick={onHide}>
          {t('signOutModal.cancel')}
        </Button>
        <a
          id='StartDemoButton'
          href={linkToAuthMethod('NORDEA', true)}
          onClick={handleStart}
        >
          {t('selectMethod.bankSelectionButton')}
        </a>
      </Modal.Footer>
    </Modal>
  )
}
export default StartDemoModal
