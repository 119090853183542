import { ReactElement, useState, ReactNode } from 'react'
import { BsChevronDown } from 'react-icons/bs'

interface Props {
  children: ReactNode
  title?: string | null
  subtitle?: string | null
  id?: string
  disable?: boolean
}

const Expandable = ({ children, title, id, subtitle, disable }: Props): ReactElement => {
  const [show, setShow] = useState(false)

  return (
    <div id={id} className={['Expandable', show && 'open'].filter(i => i !== null).join(' ')}>
      <button
        className='toggleButton'
        onClick={() => (disable === undefined || !disable) && setShow(!show)}
      >
        <div className='title'>{title}</div>
        <div className='rightContainer'>
          <div className='subtitle'>{subtitle}</div>
          {(disable === undefined || !disable) && (
            <div className='arrow'><BsChevronDown /></div>
          )}
        </div>
      </button>
      <div className='expandableContent'>
        {children}
      </div>
    </div>
  )
}
export default Expandable
