import React, { ReactElement } from 'react'
import { MdAccountCircle, MdHealthAndSafety, MdPrivacyTip } from 'react-icons/md'
import { FaFileContract, FaCookie } from 'react-icons/fa'
import { ReactComponent as WalletIcon } from '../images/Lompakko.svg'
import { ReactComponent as TaxInfoIcon } from '../images/verokortti.svg'

interface Props {
  route: 'absences' | 'salaries' | 'taxInfo' | 'terms' | 'userInfo' | 'cookieInfo' | 'privacyStatement'
  className?: string
}

const Icon = ({ route, className }: Props): ReactElement => {
  let icon: ReactElement
  switch (route) {
    case 'userInfo':
      icon = <MdAccountCircle className={className} />
      break
    case 'terms':
      icon = <FaFileContract className={className} />
      break
    case 'taxInfo':
      icon = <TaxInfoIcon className={className} />
      break
    case 'salaries':
      icon = <WalletIcon className={className} />
      break
    case 'absences':
      icon = <MdHealthAndSafety className={className} />
      break
    case 'cookieInfo':
      icon = <FaCookie className={className} />
      break
    case 'privacyStatement':
      icon = <MdPrivacyTip className={className} />
      break
  }
  return icon
}

export default Icon
