import salariesDummyData from '../dummyData/Salaries.json'
import accountDummyData from '../dummyData/Account.json'
import taxCardsDummyData from '../dummyData/TaxCards.json'
import userAppsDummyData from '../dummyData/UserApps.json'
import yearSalaries2015DummyData from '../dummyData/YearSalaries2015.json'
import yearSalaries2014DummyData from '../dummyData/YearSalaries2014.json'
import salaryDetailsDummyData from '../dummyData/SalaryDetails.json'
import absencesDummyData from '../dummyData/Absences.json'
import termsOfServiceStatusDummyData from '../dummyData/TermsOfServiceStatus.json'
import salaryConfigDummyData from '../dummyData/SalaryConfig.json'
import salaryPdf from '../dummyData/SalaryPdf.json'
import endpoints from '../config/endpoints'
import apiConfig from '../config/apiConfig'

interface ReturnProps {
  mockResponse: (method: string, path: string, data: any) => Promise<any>
}

const useMockApi = (): ReturnProps => {
  const mockResponse = async (method: string, path: string, data: any): Promise<any> => {
    return await new Promise((resolve, _reject) => {
      window.setTimeout(
        () => {
          let outcome: any
          switch (method) {
            case 'GET':
              console.log('mocking get to ', path)
              outcome = mockGet(path)
              break
            case 'POST':
              console.log('mocking post to ', path)
              outcome = mockPost(path, data)
              break
            case 'DELETE':
              console.log('mocking delete to ', path)
              outcome = mockDelete(path)
              break
          }
          resolve(outcome)
        },
        apiConfig.mockApiResponseDelay
      )
    })
  }

  const mockDelete = (path: string): any => {
    if (new RegExp(`^${endpoints.signOutPath.replace('{deviceId}', '[^/]+')}$`).test(path)) {
      return { deviceId: 'MockedDeviceId' }
    }
  }

  const mockPost = (path: string, _data: any): any => {
    switch (path) {
      case endpoints.signOutPath:
        return { deviceId: 'MockedDeviceId' }
      case endpoints.oidcPath:
        return { deviceId: 'MockedDeviceId' }
      case endpoints.newRegisterPath:
        return { token: 'MockedToken123' }
      default:
        return null
    }
  }

  const mockGet = (path: string): any => {
    if (path === endpoints.salaryConfigPath) {
      return salaryConfigDummyData
    } else if (path === endpoints.salariesPath) {
      return salariesDummyData
    } else if (path === endpoints.termsOfServiceStatusPath) {
      return termsOfServiceStatusDummyData
    } else if (path === endpoints.accountsPath) {
      return accountDummyData
    } else if (path === endpoints.taxCardsPath) {
      return taxCardsDummyData
    } else if (path === endpoints.absencesPath) {
      return absencesDummyData
    } else if (new RegExp(`^${endpoints.userAppsPath.replace('{companyId}', '[^/]+')}$`).test(path)) {
      return userAppsDummyData
    } else if (new RegExp(`^${endpoints.yearSalariesPath.replace('{year}', '[0-9]+')}$`).test(path)) {
      const parts = path.split('/')
      const year = parts[parts.length - 1]
      return year === '2014'
        ? yearSalaries2014DummyData
        : year === '2015'
          ? yearSalaries2015DummyData
          : { Salaries: { PeriodSummary: [] } }
    } else if (
      new RegExp(
        `^${endpoints.singleSalaryPath
        .replace('{companyId}', '[^/]+')
        .replace('{payday}', '[^/]+')
        .replace('{employmentId}', '[^/]+')}$`
      ).test(path)
    ) {
      return salaryDetailsDummyData
    } else if (
      new RegExp(
        `^${endpoints.salaryPdfPath
          .replace('{locale}', '[^/]+')
          .replace('{companyId}', '[^/]+')
          .replace('{payday}', '[^/]+')
          .replace('{employmentId}', '[^/]+')}$`
      ).test(path)
    ) {
      // Decode Base64 string
      const decodedData = window.atob(salaryPdf.base64)
      // Create UNIT8ARRAY of size same as row data length
      const uInt8Array = new Uint8Array(decodedData.length)
      // Insert all character code into uInt8Array
      for (let i = 0; i < decodedData.length; ++i) {
        uInt8Array[i] = decodedData.charCodeAt(i)
      }
      // Return BLOB image after conversion
      return new Blob([uInt8Array], { type: 'application/pdf' })
    }
    return null
  }

  return ({
    mockResponse
  })
}

export default useMockApi
