const firebaseConfig = {
  staging: {
    apiKey: 'AIzaSyDBA3dlQXtMFUgb190wqfAPsIWBoUhbyLs',
    authDomain: 'esimo-bba38.firebaseapp.com',
    projectId: 'esimo-bba38',
    storageBucket: 'esimo-bba38.appspot.com',
    messagingSenderId: '276121040892',
    appId: '1:276121040892:web:abe914967798b7b7d42479',
    measurementId: 'G-B3THG9DDJ9'
  },
  production: {
    apiKey: 'AIzaSyDBA3dlQXtMFUgb190wqfAPsIWBoUhbyLs',
    authDomain: 'esimo-bba38.firebaseapp.com',
    projectId: 'esimo-bba38',
    storageBucket: 'esimo-bba38.appspot.com',
    messagingSenderId: '276121040892',
    appId: '1:276121040892:web:aa6aaddf7fe1065fd42479',
    measurementId: 'G-7PQR8519DB'
  }
}

const env = process.env.REACT_APP_FIREBASE_ENV as 'staging' | 'production' | undefined
export default env !== undefined && process.env.REACT_APP_ENV !== 'test' ? firebaseConfig[env] : null
