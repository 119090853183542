import { ReactElement, useState } from 'react'
import Form from 'react-bootstrap/Form'
import FormInput from './FormInput'
import useLocalization from '../hooks/useLocalization'
import useForm from '../hooks/useForm'
import SubmitButton from './SubmitButton'
import { SalaryContactErrors, SalaryContactProps } from '../types/SalaryContact'
import useValidator from '../hooks/useValidator'
import useSalaries from '../hooks/useSalaries'
import Notification from './Notification'
import useAnalytics from '../hooks/useAnalytics'

const SalaryContactForm = (): ReactElement => {
  const { logHintMailSent } = useAnalytics()
  const { t } = useLocalization()
  const { validateSalaryContact } = useValidator()
  const { postForm, error, loading } = useSalaries()
  const [formSent, setFormSent] = useState(false)
  const [validationErrors, setValidationErrors] = useState<SalaryContactErrors>({
    CompanyName: null,
    Email: null
  })
  const { form, handleInputChange } = useForm<SalaryContactProps>({
    CompanyName: '',
    ContactName: '',
    Email: ''
  })

  const submit = async (): Promise<void> => {
    const errors = validateSalaryContact(form)
    setValidationErrors(errors)
    if (Object.values(errors).every(v => v === null)) {
      if (await postForm(form)) {
        logHintMailSent()
        setFormSent(true)
      }
    }
  }

  if (formSent) {
    return (
      <div className='SalaryForm'>
        <Notification variant='success'>
          {t('mainSalary.modalTitle')}
        </Notification>
        <p>
          {t('mainSalary.modalText')}
        </p>
      </div>
    )
  }

  return (
    <div className='SalaryForm'>
      {error !== null && (
        <Notification variant='danger'>
          {error}
        </Notification>
      )}
      <Form noValidate>
        <FormInput
          name='CompanyName'
          label={`${t('mainSalary.company')} *`}
          type='text'
          value={form.CompanyName}
          onChange={handleInputChange}
          error={validationErrors.CompanyName}
        />
        <FormInput
          name='ContactName'
          label={t('mainSalary.contact')}
          type='text'
          value={form.ContactName}
          onChange={handleInputChange}
          error={null}
        />
        <FormInput
          name='Email'
          label={`${t('mainSalary.email')} *`}
          type='email'
          value={form.Email}
          onChange={handleInputChange}
          error={validationErrors.Email}
        />
        <br />
        <SubmitButton loading={loading} label={t('mainSalary.send')} onClick={submit} />
      </Form>
    </div>
  )
}
export default SalaryContactForm
