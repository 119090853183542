import endpoints from '../config/endpoints'
import useMockApi from './useMockApi'
import apiConfig from '../config/apiConfig'
import { useContext } from 'react'
import { AuthContext } from '../contexts/AuthContext'
import fileDownload from 'js-file-download'

interface ReturnProps {
  endpoints: typeof endpoints
  get: (path: string) => Promise<any>
  getFile: (path: string, name: string) => Promise<void>
  post: (path: string, data?: any, headers?: any) => Promise<any>
  delete_: (path: string) => Promise<void>
}

const useApi = (): ReturnProps => {
  const { mockResponse } = useMockApi()
  const { token, setToken, isDemo, signOut, refreshRequestTime } = useContext(AuthContext)

  const post = async (path: string, data: any = null, headers: any = null): Promise<any> => {
    return await apiFetch(path, 'POST', data, headers)
  }

  const get = async (path: string): Promise<any> => {
    return await apiFetch(path)
  }

  const delete_ = async (path: string): Promise<any> => {
    return await apiFetch(path, 'DELETE')
  }

  const getFile = async (path: string, name: string): Promise<void> => {
    const data = await apiFetch(path, 'GET', null, null, true)
    fileDownload(data, name)
  }

  const resolveHeaders = (path: string, headers: any = null, blob: boolean = false): any => {
    const defaultHeaders = blob
      ? {
          'Cache-Control': 'no-store'
        }
      : {
          'Content-Type': 'application/json',
          'Cache-Control': 'no-store'
        }
    const shouldAddToken = path !== endpoints.oidcPath &&
      path !== endpoints.newRegisterPath && token() !== null
    return shouldAddToken
      ? { ...defaultHeaders, ...headers, Authorization: token() }
      : { ...defaultHeaders, ...headers }
  }

  const apiFetch = async (
    path: string, method: string = 'GET', data: any = null, headers: any = null, blob: boolean = false
  ): Promise<any> => {
    if (path !== endpoints.pingPath) {
      refreshRequestTime()
    }
    if (apiConfig.useMockApi) {
      return await mockResponse(method, path, data)
    }
    const url = `${(isDemo() ? apiConfig.tryOutUrl : apiConfig.apiUrl) ?? ''}/${path}`
    const response = await fetch(url, {
      method,
      headers: resolveHeaders(path, headers),
      body: data
    })
    if (!response.ok) {
      // Unauthorized? Reset the token
      if (response.status === 401) {
        signOut()
      }
      const body = await response.text()
      throw new Error(`${response.status}: ${body}`)
    }
    if (blob) {
      return await response.blob()
    }
    try {
      const json = await response.json()
      // Refresh the token
      if (json.token !== null && json.token !== undefined) {
        setToken(json.token)
      }
      return json
    } catch (e) {
      // Response is not JSON
      return null
    }
  }

  return ({
    endpoints,
    get,
    getFile,
    post,
    delete_
  })
}

export default useApi
