import { ReactElement } from 'react'
import { TaxCardInfo } from '../types/Account'
import useLocalization from '../hooks/useLocalization'
import useFormatter from '../hooks/useFormatter'
import InfoRow from './InfoRow'

interface Props {
  taxCard: TaxCardInfo | null
}

const TaxInfoPercentages = ({ taxCard }: Props): ReactElement => {
  const { t } = useLocalization()
  const { formatDate, formatPercentage } = useFormatter()

  if (taxCard === null) {
    return (
      <div className='TaxInfoCard'>
        <div className='noTaxCard'>{t('taxInformation.noTaxCard')}</div>
      </div>
    )
  }

  return (
    <div className='TaxInfoCard'>
      <InfoRow
        id='taxInfoPercentagesTitle'
        variant='title'
        label={t('taxInformation.withholdingPercentages')}
        value={formatDate(taxCard.StartDate, 'dateOnly') + '-'}
      />
      {taxCard.BasicPercentKey !== null && (
        <InfoRow
          id='taxInfoBasicPercentKey'
          label={t(`taxInformation.${taxCard.BasicPercentKey}`)}
          value={formatPercentage(taxCard.BasicPercent)}
        />
      )}
      {taxCard.ExtraPercentKey !== null && (
        <InfoRow
          id='taxInfoExtraPercentKey'
          label={t(`taxInformation.${taxCard.ExtraPercentKey}`)}
          value={formatPercentage(taxCard.ExtraPercent)}
        />
      )}
      {taxCard.StepPercentKey3 !== null && (
        <InfoRow
          id='taxInfoStepPercentKey3'
          label={t(`taxInformation.${taxCard.StepPercentKey3}`)}
          value={formatPercentage(taxCard.StepPercent3)}
        />
      )}
    </div>
  )
}
export default TaxInfoPercentages
