import { ReactElement } from 'react'
import Account from '../types/Account'
import InfoRow from './InfoRow'
import useLocalization from '../hooks/useLocalization'

interface Props {
  account?: Account | null
}

const UserInfoCard = ({ account }: Props): ReactElement => {
  const { t } = useLocalization()
  const personalData = account?.AccountInfo?.PersonalData

  if (account === undefined || account === null) {
    return (
      <div className='UserInfoCard' />
    )
  }

  return (
    <div className='UserInfoCard'>
      <InfoRow
        variant='title'
        label={t('ownInformation.basicInfo')}
      />
      <InfoRow
        id='userInfoName'
        label={t('ownInformation.name')}
        value={personalData?.Name}
      />
      <InfoRow
        id='userInfoAddress'
        label={t('ownInformation.address')}
        value={personalData?.Address}
      />
      {personalData?.BankAccount?.map?.((bankAccount, i) =>
        <InfoRow
          id={`userInfoBankAccount${i}`}
          key={`userInfoBankAccount${i}`}
          label={t('ownInformation.accountNumber')}
          value={bankAccount.toUpperCase()}
        />
      )}
    </div>
  )
}
export default UserInfoCard
