import { useContext } from 'react'
import { LocaleContext } from '../contexts/LocaleContext'
import SalaryConfig from '../types/SalaryConfig'

interface Description {
  Description: string | null
  DescriptionEN?: string | null
  DescriptionSV?: string | null
}

interface ReturnProps {
  t: (key: string) => string
  tList: <T>(key: string) => T[]
  tExists: (key?: string | null) => boolean
  tSalaryDescription: (item: Description) => string
  tSalaryExplanation: (
    row: { Description: string | null },
    payday: string,
    sicknessPayment: string | null,
    config: SalaryConfig | null
  ) => string | null
}

type descriptionPostfix = 'EN' | 'SV'

const useLocalization = (): ReturnProps => {
  const { translations, locale } = useContext(LocaleContext)

  const t = (key: string): string => {
    const translation = key.split('.').reduce((acc, v) => acc?.[v], translations)
    if (Array.isArray(translation)) {
      return translation[0]
    }
    return translation !== undefined ? translation : key
  }

  const tList = <T>(key: string): T[] => {
    const translation = key.split('.').reduce((acc, v) => acc?.[v], translations)
    if (!Array.isArray(translation)) {
      return []
    }
    return translation
  }

  const tExists = (key?: string | null): boolean => {
    if (key === undefined || key === null) {
      return false
    }
    const translation = key.split('.').reduce((acc, v) => acc?.[v], translations)
    return translation !== undefined
  }

  const tSalaryDescription = (item: Description): string => {
    const { Description } = item
    if (locale === 'fi') {
      return typeof Description === 'string' ? Description.trim() : ''
    }
    const tDescription = item[`Description${locale.toUpperCase() as descriptionPostfix}`]
    if (typeof tDescription === 'string' && tDescription.trim() !== '') {
      if (locale === 'en' && tDescription.includes('Pidettu')) { // has fin text in eng field
        return "Last year's WTR leave"
      }
      return tDescription.trim()
    } else if (typeof Description === 'string' && Description.trim() !== '') {
      return Description
    }
    return ''
  }

  const tSalaryExplanation = (
    row: { Description: string | null },
    payday: string,
    sicknessPayment: string | null,
    config: SalaryConfig | null
  ): string | null => {
    const { Description } = row
    if (typeof Description !== 'string' || Description.trim() === '') {
      return null
    }
    let explanation = null
    const cfg = config?.explanations?.[Description] ?? null
    if (cfg !== null) {
      const tmp = [...cfg].sort((a, b) =>
        Date.parse(a.validFrom) > Date.parse(b.validFrom) ? -1 : 1
      )
      for (const exp of tmp) {
        if (Date.parse(exp.validFrom) < Date.parse(payday)) {
          explanation = exp.description?.[locale] ?? null
          break
        }
      }
    }
    if (explanation === null && tExists(`salaryExplanations.${Description}`)) {
      explanation = t(`salaryExplanations.${Description}`)
    }
    if (Description === 'Ennakonpidätys' && sicknessPayment !== null) {
      explanation = [
        explanation,
        t('salaryExplanations.sicknessPayment'),
        sicknessPayment
      ].filter(exp => exp).join(' ')
    }
    return explanation
  }

  return {
    t,
    tList,
    tExists,
    tSalaryDescription,
    tSalaryExplanation
  }
}
export default useLocalization
