import React, { ReactElement, useContext } from 'react'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import useLocalization from '../hooks/useLocalization'
import { ResourceContext } from '../contexts/ResourceContext'
import useAuth from '../hooks/useAuth'
import { useNavigate } from 'react-router-dom'
import SubmitButton from './SubmitButton'

interface Props {
  show: boolean
  onHide: () => void
}

const SignOutModal = ({ show, onHide }: Props): ReactElement => {
  const { resetResources } = useContext(ResourceContext)
  const { signOut, loading } = useAuth()
  const { t } = useLocalization()
  const navigate = useNavigate()

  const handleSignOut = async (): Promise<void> => {
    resetResources()
    await signOut()
    onHide()
    navigate('/')
  }

  return (
    <Modal show={show} onHide={onHide} className='SignOutModal'>
      <Modal.Header closeButton>
        <Modal.Title>{t('signOutModal.title')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{t('signOutModal.body')}</Modal.Body>
      <Modal.Footer>
        <Button variant='secondary' onClick={onHide}>
          {t('signOutModal.cancel')}
        </Button>
        <SubmitButton
          loading={loading}
          label={t('signOutModal.confirm')}
          onClick={handleSignOut}
        />
      </Modal.Footer>
    </Modal>
  )
}
export default SignOutModal
