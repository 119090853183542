import React, { useContext } from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import { AuthContext } from '../contexts/AuthContext'

const PrivateRoute: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  const { isAuth, termsAgreed } = useContext(AuthContext)
  if (!isAuth) {
    return <Navigate to='/' state={{ authFail: true }} />
  } else if (isAuth && !termsAgreed) {
    return <Navigate to='/agree_terms' />
  }
  return <>{children ?? <Outlet />}</>
}
export default PrivateRoute
