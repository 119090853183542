import { ReactElement, ReactNode } from 'react'
import { Alert } from 'react-bootstrap'

interface Props {
  variant: 'primary' | 'secondary' | 'success' | 'danger' | 'warning' | 'info' | 'light' | 'dark'
  children: ReactNode
  dismissible?: boolean
  onClick?: () => void
}

const Notification = ({ variant, children, dismissible, onClick }: Props): ReactElement => {
  return (
    <div className='Notification'>
      <Alert variant={variant} dismissible={dismissible} onClick={onClick}>
        {children}
      </Alert>
    </div>
  )
}
export default Notification
