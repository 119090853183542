import React, { ReactElement } from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'

interface Props {
  name: string
  label?: string
  type: 'text' | 'date' | 'file' | 'email'
  placeholder?: string
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  value?: string
  error: string | null
  id?: string
  horizontal?: boolean
}

const FormInput = (
  { id, horizontal, error, name, value, label, type, placeholder, onChange }: Props
): ReactElement => {
  const inputElement = (): ReactElement => (
    <>
      <Form.Control
        isInvalid={error !== null}
        name={name}
        value={value}
        onChange={onChange}
        type={type}
        placeholder={placeholder}
      />
      <Form.Control.Feedback type='invalid'>
        {error}
      </Form.Control.Feedback>
    </>
  )

  return (
    <Row id={id}>
      {horizontal !== undefined && horizontal && (
        <>
          <Form.Label column xs={5}>
            {label}
          </Form.Label>
          <Col xs={7}>
            {inputElement()}
          </Col>
        </>
      )}
      {(horizontal === undefined || !horizontal) && (
        <Col>
          {label !== undefined && (
            <Form.Label>
              {label}
            </Form.Label>
          )}
          {inputElement()}
        </Col>
      )}
    </Row>
  )
}
export default FormInput
