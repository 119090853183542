import React, { ReactElement, useState, useContext, useEffect, useRef } from 'react'
import { Outlet } from 'react-router-dom'
import Header from './components/Header'
import Menu from './components/Menu'
import Footer from './components/Footer'
import Reloader from './components/Reloader'
import { AuthContext } from './contexts/AuthContext'
import SessionTimeWatcher from './components/SessionTimeWatcher'
import CookieConsentDialog from './components/CookieConsentDialog'
import useAnalytics from './hooks/useAnalytics'
import session from './config/session'

function App (): ReactElement {
  const { cookieConsent } = useAnalytics()
  const { isAuth, termsAgreed } = useContext(AuthContext)
  const timeoutRef = useRef<number | null>(null)
  const [showConsentDialog, setShowConsentDialog] = useState(false)

  const showMenu = isAuth && termsAgreed

  useEffect(() => {
    if (session.showCookieConsentDialog && cookieConsent === null) {
      timeoutRef.current = window.setTimeout(() => setShowConsentDialog(true), 2000)
    }
    return () => {
      if (timeoutRef.current !== null) {
        window.clearTimeout(timeoutRef.current)
      }
    }
  }, [cookieConsent])

  return (
    <div className='App'>
      {showConsentDialog && <CookieConsentDialog onClose={() => setShowConsentDialog(false)} />}
      <SessionTimeWatcher />
      <Reloader />
      <Header />
      <div className='Main'>
        {showMenu && (
          <>
            <div className='menuContainer'>
              <Menu onCookieInfoClick={() => setShowConsentDialog(true)} />
            </div>
            <div className='mainContainer'>
              <Outlet />
            </div>
          </>
        )}
        {!showMenu && (
          <div className={!isAuth ? 'landingContainer' : 'termsContainer'}>
            <Outlet />
          </div>
        )}
      </div>
      <Footer onCookieInfoClick={() => setShowConsentDialog(true)} />
    </div>
  )
}

export default App
