import { ReactElement } from 'react'
import InfoRow from './InfoRow'
import useLocalization from '../hooks/useLocalization'
import useFormatter from '../hooks/useFormatter'

interface Item {
  Value: string | null
  Description: string | null
  DescriptionEN?: string | null
  DescriptionSV?: string | null
  Quantity?: string | null
  UnitPrice?: string | null
  Start?: string | null
  End?: string | null
}

interface Props {
  item: Item
  id: string
}

const SalaryDetailsRow = ({ item, id }: Props): ReactElement => {
  const { formatCurrency, formatDate } = useFormatter()
  const { tSalaryDescription } = useLocalization()

  const subLabel = (): string | null => {
    const start = formatDate(item.Start, 'dateOnly')
    const end = formatDate(item.End, 'dateOnly')
    if (start === '' && end === '') {
      return null
    }
    if (start === end) {
      return start
    } else {
      return `${start} - ${end}`
    }
  }

  const subValue = (): string | null => {
    if (
      item.Quantity !== undefined &&
      item.Quantity !== null &&
      item.Quantity !== '0.00' &&
      item.UnitPrice !== undefined &&
      item.UnitPrice !== null &&
      item.UnitPrice !== '0.00'
    ) {
      return `${item.Quantity} × ${formatCurrency(item.UnitPrice)}`
    }
    return null
  }

  return (
    <InfoRow
      id={id}
      label={tSalaryDescription(item)}
      subLabel={subLabel()}
      value={formatCurrency(item.Value)}
      subValue={subValue()}
    />
  )
}
export default SalaryDetailsRow
