import React, { ReactElement } from 'react'
import SalaryDetails, { DeductionSummaryDeduction } from '../types/SalaryDetails'
import useFormatter from '../hooks/useFormatter'
import useLocalization from '../hooks/useLocalization'
import SalaryPeriodSummary from '../types/SalaryPeriodSummary'
import SalaryDetailsSection from './SalaryDetailsSection'
import Button from 'react-bootstrap/Button'
import Spinner from 'react-bootstrap/Spinner'
import { BsFilePdfFill } from 'react-icons/bs'

interface Props {
  salary: SalaryDetails
  summary: SalaryPeriodSummary
  loadingPdf: boolean
  onDownloadPdf: () => void
}

const SalaryDetailsList = ({ salary, summary, loadingPdf, onDownloadPdf }: Props): ReactElement => {
  const { formatDate, formatCurrency } = useFormatter()
  const { t } = useLocalization()

  const periodStart = summary.PeriodStart !== null ? new Date(summary.PeriodStart) : null
  const periodEnd = summary.PeriodEnd !== null ? new Date(summary.PeriodEnd) : null

  const sicknessPayment = (): string | null => {
    let payment = null
    for (const deduction of (salary.DeductionSummary?.Deductions ?? [])) {
      if (deduction.Description === 'Sairaspäivärahamaksu') {
        payment = deduction.Sum
      }
    }
    return payment
  }

  const deductions = (): DeductionSummaryDeduction[] => {
    if (!Array.isArray(salary.DeductionSummary?.Deductions)) {
      return []
    }
    return salary.DeductionSummary?.Deductions.filter(d => (
      d.Description !== 'Sairaspäivärahamaksu'
    ))
  }

  return (
    <div className='SalaryDetailsList'>
      <div id='SalaryOverview' className='salaryOverview'>
        <div className='salaryOverviewTopRow'>
          {periodStart !== null && periodEnd !== null && (
            <div className='monthAndYear'>
              {periodStart.getFullYear() === periodEnd.getFullYear() &&
                `${formatDate(periodStart, 'dayAndMonth')} - ${formatDate(periodEnd, 'dateOnly')}`}
              {periodStart.getFullYear() !== periodEnd.getFullYear() &&
                `${formatDate(periodStart, 'dateOnly')} - ${formatDate(periodEnd, 'dateOnly')}`}
            </div>
          )}
          {(periodStart === null || periodEnd === null) && (
            <div />
          )}
          <Button
            id='DownloadPdfButton'
            variant='primary'
            onClick={onDownloadPdf}
            disabled={loadingPdf}
          >
            {loadingPdf && <Spinner animation='border' role='status' variant='light' size='sm' />}
            {t('singleSalary.downloadPdf')} <BsFilePdfFill />
          </Button>
        </div>
        <div className='salaryTotalContainer'>
          <div className='salaryTotal'>{formatCurrency(summary.Total)}</div>
          <div className='salaryTotalPayday'>
            {new Date(summary.Payday).valueOf() < Date.now()
              ? t('mainSalary.wasPaid')
              : t('mainSalary.toBePaid')}
            &nbsp;{formatDate(summary.Payday, 'dateOnly')}
          </div>
        </div>
        <div className='companyName'>
          {summary.CompanyName}
        </div>
      </div>
      <div className='salaryColumnContainer'>
        <div className='salaryColumn'>
          {Array.isArray(salary.WageSummary?.Wages) && salary.WageSummary.Wages.length > 0 && (
            <SalaryDetailsSection
              id='WageSummary'
              payday={summary.Payday}
              sicknessPayment={sicknessPayment()}
              title={t('singleSalary.salary')}
              total={salary.WageSummary.Total}
              items={salary.WageSummary.Wages.map(w => ({ ...w, SubItems: w.Wage ?? [] }))}
            />
          )}
          {Array.isArray(salary.PerkSummary?.Perks) && salary.PerkSummary.Perks.length > 0 && (
            <SalaryDetailsSection
              id='PerkSummary'
              payday={summary.Payday}
              sicknessPayment={sicknessPayment()}
              title={t('singleSalary.benefits')}
              total={salary.PerkSummary.Total}
              items={salary.PerkSummary.Perks.map(w => ({ ...w, SubItems: w.Perk ?? [] }))}
            />
          )}
        </div>
        <div className='salaryColumn'>
          {deductions().length > 0 && (
            <SalaryDetailsSection
              id='DeductionSummary'
              payday={summary.Payday}
              sicknessPayment={sicknessPayment()}
              title={t('singleSalary.subtractions')}
              total={salary.DeductionSummary.Total}
              items={deductions().map(w => ({ ...w, SubItems: w.Deduction ?? [] }))}
            />
          )}
          {Array.isArray(salary.CompensationSummary?.Compensations) &&
            salary.CompensationSummary.Compensations.length > 0 && (
              <SalaryDetailsSection
                id='CompensationSummary'
                payday={summary.Payday}
                sicknessPayment={sicknessPayment()}
                title={t('singleSalary.compensations')}
                total={salary.CompensationSummary.Total}
                items={salary.CompensationSummary.Compensations.map(w => ({ ...w, SubItems: w.Compensation ?? [] }))}
              />
          )}
        </div>
      </div>
    </div>
  )
}
export default SalaryDetailsList
