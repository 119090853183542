import React, { ReactElement, useContext, useEffect, useState } from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import Button from 'react-bootstrap/Button'
import useLocalization from '../hooks/useLocalization'
import { AuthContext } from '../contexts/AuthContext'
import StartDemoModal from '../components/StartDemoModal'
import AuthMethodModal from '../components/AuthMethodModal'
import useAnalytics from '../hooks/useAnalytics'
import logo from '../images/siltalogo_transparent.png'
import Notification from '../components/Notification'

const Landing = (): ReactElement => {
  const { logPageView } = useAnalytics()
  const { t } = useLocalization()
  const { isAuth } = useContext(AuthContext)
  const [showDemoModal, setShowDemoModal] = useState(false)
  const [showAuthModal, setShotAuthModal] = useState(false)
  const [showNotification, setShowNotification] = useState(false)
  const [showError, setShowError] = useState(false)
  const { state } = useLocation()

  useEffect(() => {
    logPageView('Landing')
    if (state?.authFail as boolean) {
      setShowNotification(true)
    } else if (state?.error !== undefined && state?.error !== null) {
      setShowError(true)
    }
  }, [])

  if (isAuth) {
    return <Navigate to='/salaries' />
  }

  return (
    <div className='Landing'>
      {showError &&
        <Notification
          variant='danger'
          dismissible
          onClick={() => setShowError(false)}
        >
          {t('errors.registerError.text')}
        </Notification>}
      {showNotification &&
        <Notification
          variant='warning'
          dismissible
          onClick={() => setShowNotification(false)}
        >
          <strong>{t('authenticate.invalidDevice.title')}</strong><br />
          {t('authenticate.invalidDevice.message')}
        </Notification>}
      <div className='column'>
        <div className='logoContainer'>
          <img alt='logo' src={logo} className='logo' />
          <span className='title'>e&#x200A;<strong>Simo</strong></span>
        </div>
        <div className='slogan'>
          <div className='sloganBorder' />
          <span>{t('topBenefits.slogan')}</span>
          <div className='sloganBorder' />
        </div>
        <div className='actions'>
          <Button onClick={() => setShowDemoModal(true)} className='actionButton' id='TestSimoButton'>
            {t('topBenefits.testSimo')}
          </Button>
          <Button onClick={() => setShotAuthModal(true)} className='actionButton' id='StartSimoButton'>
            {t('topBenefits.getStarted')}
          </Button>
        </div>
      </div>
      <StartDemoModal show={showDemoModal} onHide={() => setShowDemoModal(false)} />
      <AuthMethodModal show={showAuthModal} onHide={() => setShotAuthModal(false)} />
    </div>
  )
}

export default Landing
