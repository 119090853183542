import React, { ReactElement } from 'react'
import Spinner from 'react-bootstrap/Spinner'

const Loader = (): ReactElement => {
  return (
    <div className='Loader'>
      <Spinner animation='border' role='status' variant='secondary' />
    </div>
  )
}
export default Loader
