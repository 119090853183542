import { useEffect, useRef } from 'react'
import useStorage from '../hooks/useStorage'
import versions from '../config/versions'

// Set the name of the hidden property and the change event for visibility
let visibilityChange: string
const doc: Document & { msHidden?: boolean, webkitHidden?: boolean } = document
if (typeof doc.hidden !== 'undefined') { // Opera 12.10 and Firefox 18 and later support
  visibilityChange = 'visibilitychange'
} else if (typeof doc.msHidden !== 'undefined') {
  visibilityChange = 'msvisibilitychange'
} else if (typeof doc.webkitHidden !== 'undefined') {
  visibilityChange = 'webkitvisibilitychange'
}

const Reloader = (): null => {
  const timeout = useRef<number | null>(null)
  const { setLastReload, getLastReload } = useStorage()

  const reload = async (): Promise<void> => {
    if (typeof caches !== 'undefined') {
      // Service worker cache should be cleared with caches.delete()
      const names = await caches.keys()
      for (const name of names) {
        await caches.delete(name)
      }
    }
    // delete browser cache and hard reload
    window.location.reload()
  }

  const onFocus = async (): Promise<void> => {
    const lastReload = getLastReload()
    if (lastReload !== null && Date.now() - lastReload < 60000) {
      return
    }
    try {
      const response = await fetch(`/meta.json?t=${Date.now()}`)
      const meta = await response.json()
      console.log('meta.json loaded')
      const version = versions.app
      const latestVersion = meta.version
      if (
        typeof latestVersion === 'string' &&
        typeof version === 'string' &&
        latestVersion !== version
      ) {
        setLastReload(Date.now())
        // console.log(`Reloading page. Latest version is ${latestVersion} and current is ${version}`)
        // setTimeout(() => reload(), 1000)
        await reload()
      }
    } catch (error) {
      console.error('Error loading meta.json', error)
    }
  }

  const handleVisibilityChange = (): void => {
    if (document.hasFocus()) {
      if (timeout.current !== null) {
        window.clearInterval(timeout.current)
      }
      timeout.current = window.setTimeout(() => {
        void onFocus()
      }, 500)
    }
  }

  useEffect(
    () => {
      if (process.env.REACT_APP_ENV === 'test') {
        return
      }
      // Warn if the browser doesn't support addEventListener or the Page Visibility API
      if (typeof document.addEventListener === 'undefined') {
        console.log('Visibility API not supported')
      } else {
        document.addEventListener(visibilityChange, handleVisibilityChange, false)
      }
      window.addEventListener('focus', handleVisibilityChange, false)
      window.addEventListener('pageshow', handleVisibilityChange, false) // For iOS
      return () => {
        document.removeEventListener(visibilityChange, handleVisibilityChange)
        window.removeEventListener('focus', handleVisibilityChange)
        window.removeEventListener('pageshow', handleVisibilityChange)
      }
    },
    []
  )
  return null
}

export default Reloader
