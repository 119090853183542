import React, { ReactElement } from 'react'
import Button from 'react-bootstrap/Button'
import { BsChevronLeft } from 'react-icons/bs'
import useLocalization from '../hooks/useLocalization'
import { useNavigate } from 'react-router-dom'

interface Props {
  to: string
  size?: 'sm' | 'lg'
  visibleScreenSize?: 'sm' | 'md'
}

const BackButton = ({ to, size, visibleScreenSize }: Props): ReactElement => {
  const { t } = useLocalization()
  const navigate = useNavigate()

  return (
    <Button
      size={size}
      variant='outline-primary'
      onClick={() => navigate(to)}
      className={`BackButton ${visibleScreenSize ?? ''}`}
    >
      <BsChevronLeft className='chevron' />
      {t('wizard.back')}
    </Button>
  )
}
export default BackButton
