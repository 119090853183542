import useLocalization from './useLocalization'

type dateFormatType =
  'dateOnly' | 'yearOnly' | 'monthAndYear' |
  'absencePostDate' | 'monthOnly' | 'dayAndMonth'

interface ReturnProps {
  formatDate: (dateText: Date | string | null | undefined, format?: dateFormatType) => string
  formatPercentage: (percentage: number | string | null) => string
  formatCurrency: (currency: number | string | null) => string
}

const thinSpace = String.fromCharCode(0x2009)

const useFormatter = (): ReturnProps => {
  const { t } = useLocalization()

  const formatDate = (dateParam: Date | string | null | undefined, format?: dateFormatType): string => {
    if (
      dateParam === undefined ||
      dateParam === null ||
      dateParam === '' ||
      dateParam === '1900-01-01T00:00:00' ||
      dateParam === '1900-01-01'
    ) {
      return ''
    }
    const date = typeof dateParam !== 'string' ? dateParam : new Date(dateParam)
    switch (format) {
      case 'yearOnly':
        return `${date.getFullYear()}`
      case 'dayAndMonth':
        return `${date.getDate()}.${date.getMonth() + 1}.`
      case 'dateOnly':
        return `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`
      case 'monthAndYear':
        return `${t(`month.${date.getMonth() + 1}`)} ${date.getFullYear()}`
      case 'monthOnly':
        return `${t(`month.${date.getMonth() + 1}`)}`
      case 'absencePostDate':
        return date.getFullYear().toString(10) +
          (date.getMonth() + 1).toString(10).padStart(2, '0') +
          date.getDate().toString(10).padStart(2, '0')
      default:
        return date.toUTCString()
    }
  }

  const formatPercentage = (percentage: number | string | null): string => {
    let ret = ''
    if (typeof percentage === 'number') {
      ret = percentage.toFixed(1)
    } else if (typeof percentage === 'string') {
      ret = parseFloat(percentage).toFixed(1)
    } else {
      return ''
    }
    return `${ret.replace('.', ',')}${thinSpace}%`
  }

  const formatCurrency = (currency: number | string | null): string => {
    let ret = ''
    if (typeof currency === 'number') {
      ret = currency.toFixed(2)
    } else if (typeof currency === 'string') {
      const tmp = parseFloat(currency)
      if (isNaN(tmp)) {
        return ''
      }
      ret = tmp.toFixed(2)
    } else {
      return ''
    }
    const separator = ','
    const tSeparator = thinSpace
    const parts = ret.split('.')
    const ary = Array.from(parts[0])
    // Thousands
    ary.splice(-3, 0, tSeparator)
    // Millions
    if (ary.length > 7) {
      ary.splice(-7, 0, tSeparator)
    }
    return `${ary.join('')}${separator}${parts[1]}${thinSpace}€`
  }

  return {
    formatDate,
    formatPercentage,
    formatCurrency
  }
}
export default useFormatter
