import React, { ReactElement, useContext, useEffect } from 'react'
import PageTitle from '../components/PageTitle'
import useLocalization from '../hooks/useLocalization'
import Button from 'react-bootstrap/Button'
import { Navigate } from 'react-router-dom'
import useAuth from '../hooks/useAuth'
import useUser from '../hooks/useUser'
import Notification from '../components/Notification'
import SubmitButton from '../components/SubmitButton'
import { AuthContext } from '../contexts/AuthContext'
import versions from '../config/versions'
import useAnalytics from '../hooks/useAnalytics'
import Icon from '../components/Icon'

interface Props {
  agreeable?: boolean
}

const Terms = ({ agreeable }: Props): ReactElement => {
  const { logPageView } = useAnalytics()
  const { isAuth, termsAgreed, setAgreedTermsVersion } = useContext(AuthContext)
  const { logTermsNotAgreed, logTermsAgreed } = useAnalytics()
  const { signOut, loading: signingOut } = useAuth()
  const { t, tList } = useLocalization()
  const { postTermsOfServiceAgreement, error, loading } = useUser()

  useEffect(() => {
    logPageView('Terms of use')
  }, [])

  const handleCancel = (): void => {
    logTermsNotAgreed()
    signOut()
  }

  const handleAgree = async (): Promise<void> => {
    const status = await postTermsOfServiceAgreement()
    if (status) {
      logTermsAgreed()
      setAgreedTermsVersion(versions.termsOfService)
    }
  }

  const formatLinks = (content: string): ReactElement[] => {
    const parts = content.split('www.silta.fi')
    return parts.map((p, i) => {
      return [
        <span key={`c${i}`}>{p}</span>,
        i < parts.length - 1
          ? <a key={`cl${i}`} href='https://www.silta.fi' rel='noreferrer' target='_blank'>www.silta.fi</a>
          : <span key={`ce${i}`} />
      ]
    }).flat()
  }

  const formatContent = (content: string): ReactElement[] => {
    const paragraphs = content.split('\n')
    return paragraphs.map((p, i) => <p key={`p${i}`}>{formatLinks(p)}</p>)
  }

  if (agreeable === true && !isAuth) {
    return <Navigate to='/' />
  } else if (agreeable === true && termsAgreed) {
    return <Navigate to='/salaries' />
  }

  return (
    <div className='Terms'>
      <PageTitle icon={<Icon route='terms' />}>
        {t('termsConditions.title')}
      </PageTitle>
      <div className='content'>
        {error !== null && <Notification variant='danger'>{error}</Notification>}
        {tList<{ heading?: string, content: string }>('termsConditions.terms').map((item, index) => (
          <div
            className='itemSection'
            key={`termsSection${index}`}
            id={`termsSection${index}`}
          >
            {item.heading !== undefined && (
              <div className='itemHeading'>{index}. {item.heading}</div>
            )}
            <div className='itemContent'>{formatContent(item.content)}</div>
          </div>
        ))}
        {agreeable === true && (
          <div className='actions'>
            <Button variant='secondary' onClick={handleCancel} disabled={loading || signingOut}>
              {t('termsConditions.cancel')}
            </Button>
            <SubmitButton
              onClick={handleAgree}
              loading={loading || signingOut}
              label={t('termsConditions.agree')}
            />
          </div>
        )}
      </div>
    </div>
  )
}
export default Terms
