import { ReactElement } from 'react'
import InfoRow from './InfoRow'
import ProgressBar from './ProgressBar'
import { TaxCardInfo } from '../types/Account'
import useFormatter from '../hooks/useFormatter'
import useLocalization from '../hooks/useLocalization'

interface Props {
  taxCard: TaxCardInfo | null
}

const TaxInfoLimits = ({ taxCard }: Props): ReactElement => {
  const { t } = useLocalization()
  const { formatCurrency } = useFormatter()

  const resolveProgressPercentage = (): number => {
    if (taxCard === null || taxCard.IncomeLimit1 === null || taxCard.CumulativeIncome === null) {
      return 0
    }
    const limit = parseFloat(taxCard.IncomeLimit1)
    const income = parseFloat(taxCard.CumulativeIncome)
    if (!isNaN(limit) && !isNaN(income) && limit !== 0) {
      return Math.min(income / limit, 1)
    }
    return 0
  }

  if (taxCard === null) {
    return <div className='TaxInfoLimits' />
  }

  return (
    <div className='TaxInfoLimits'>
      <InfoRow
        id='taxInfoLimitsTitle'
        variant='title'
        label={t('taxInformation.incomeLimits')}
      />
      {taxCard.IncomeLimitKey1 !== null && (
        <InfoRow
          id='taxInfoIncomeLimitKey1'
          label={t(`taxInformation.${taxCard.IncomeLimitKey1}`)}
          value={formatCurrency(taxCard.IncomeLimit1)}
        />
      )}
      {taxCard.IncomeLimitKey2 !== null && (
        <InfoRow
          id='taxInfoIncomeLimitKey2'
          label={t(`taxInformation.${taxCard.IncomeLimitKey2}`)}
          value={formatCurrency(taxCard.IncomeLimit2)}
        />
      )}
      {taxCard.IncomeLimitKey3 !== null && (
        <InfoRow
          id='taxInfoIncomeLimitKey3'
          label={t(`taxInformation.${taxCard.IncomeLimitKey3}`)}
          value={formatCurrency(taxCard.IncomeLimit3)}
        />
      )}
      {taxCard.CumulativeIncome !== null && (
        <ProgressBar
          title={t('taxInformation.incomeFromStartOfYear')}
          label={formatCurrency(taxCard.CumulativeIncome)}
          progress={resolveProgressPercentage() * 100}
        />
      )}
    </div>
  )
}

export default TaxInfoLimits
