import { ReactElement } from 'react'
import BootstrapProgressBar from 'react-bootstrap/ProgressBar'

interface Props {
  title?: string | string[]
  progress: number
  label: string
}

const ProgressBar = ({ title, progress, label }: Props): ReactElement => {
  const resolveClass = (postfix: string = 'progress'): string => {
    let prefix = 'low'
    if (progress >= 100) {
      prefix = 'high'
    } else if (progress >= 80) {
      prefix = 'medium'
    }
    return `${prefix} ${postfix}`
  }

  return (
    <div className='ProgressBar'>
      {title !== undefined && <div className='title'>{title}</div>}
      <BootstrapProgressBar now={progress} visuallyHidden bsPrefix={resolveClass()} />
      <div
        className={resolveClass('label')}
        style={{ left: `${progress}%`, transform: `translateX(-${progress}%)` }}
      >
        {label}
      </div>
    </div>
  )
}
export default ProgressBar
