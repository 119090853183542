import React, { ReactElement } from 'react'
import Button from 'react-bootstrap/Button'
import Spinner from 'react-bootstrap/Spinner'

interface Props {
  label: string
  onClick: () => void
  loading?: boolean
  variant?: string
  disabled?: boolean
}

const SubmitButton = ({ disabled, loading, label, onClick, variant }: Props): ReactElement => {
  return (
    <Button
      variant={variant ?? 'primary'}
      onClick={onClick}
      disabled={loading === true || disabled === true}
      className='SubmitButton'
    >
      {loading === true && <Spinner animation='border' role='status' variant='light' size='sm' />}
      {label}
    </Button>
  )
}
export default SubmitButton
