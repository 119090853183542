import { ReactElement } from 'react'
import { Link } from 'react-router-dom'
import { BsChevronRight } from 'react-icons/bs'

interface Props {
  id?: string
  label?: string | string[] | null
  subLabel?: string | string[] | null
  value?: string | string[] | null
  subValue?: string | string[] | null
  variant?: 'title' | 'boldTitle' | 'titleWithMargin' | 'smallPrint'
  linkTo?: string
}

const InfoRow = ({ id, label, subLabel, value, subValue, variant, linkTo }: Props): ReactElement => {
  if (linkTo !== undefined) {
    return (
      <Link
        to={linkTo}
        className={['InfoRow', 'link', variant].filter(i => i !== undefined).join(' ')}
        id={id}
      >
        <div className='label'>
          {label}
          <div className='subLabel'>
            {subLabel}
          </div>
        </div>
        <div className='value'>
          {value}
          <BsChevronRight className='chevron' />
        </div>
      </Link>
    )
  }

  return (
    <div className={['InfoRow', variant].filter(i => i !== undefined).join(' ')} id={id}>
      <div className='label'>
        {label}
        {subLabel !== undefined && subLabel !== null && (
          <div className='subLabel'>
            {subLabel}
          </div>
        )}
      </div>
      <div className='value'>
        {value}
        {subValue !== undefined && subValue !== null && (
          <div className='subValue'>
            {subValue}
          </div>
        )}
      </div>
    </div>
  )
}
export default InfoRow
