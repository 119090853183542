import React, { ReactElement, useEffect, useMemo } from 'react'
import { Link, useParams } from 'react-router-dom'
import PageTitle from '../components/PageTitle'
import useLocalization from '../hooks/useLocalization'
import useSalaries from '../hooks/useSalaries'
import Loader from '../components/Loader'
import Notification from '../components/Notification'
import SalaryYearSummaryList from '../components/SalaryYearSummaryList'
import BackButton from '../components/BackButton'
import { ButtonGroup } from 'react-bootstrap'
import useAnalytics from '../hooks/useAnalytics'
import useUser from '../hooks/useUser'
import useAccount from '../hooks/useAccount'
import SalaryPeriodSummary from '../types/SalaryPeriodSummary'
import Icon from '../components/Icon'

const SalariesYear = (): ReactElement => {
  const { logPageView } = useAnalytics()
  const { userApps, getUserApps, loading: loadingUserApps } = useUser()
  const { getAccount, loading: loadingAccount } = useAccount()
  const { getYearPeriodSummaries, yearPeriodSummaries, loading, error } = useSalaries()
  const { t } = useLocalization()
  const { year } = useParams()

  useEffect(() => {
    logPageView('Salaries for year')
    void getAccount().then(account => {
      if (account !== null) {
        void getUserApps(account)
      }
    })
    if (year !== undefined) {
      void getYearPeriodSummaries(parseInt(year, 10))
    }
    // eslint-disable-next-line
  }, [year])

  const allowedYearPeriodSummaries = useMemo<SalaryPeriodSummary[]>(() => (
    (yearPeriodSummaries ?? []).filter(summary => (
      userApps[summary.CompanyId]?.App3
    ))
  ), [yearPeriodSummaries, userApps])

  if (loading || loadingUserApps || loadingAccount) {
    return <Loader />
  }

  return (
    <div className='SalariesYear'>
      <PageTitle icon={<Icon route='salaries' />}>
        <div>
          <Link to='/salaries'>{t('mainSalary.title')}</Link>
          {' - '}
          {year}
        </div>
        <BackButton
          to='/salaries'
          size='sm'
          visibleScreenSize='md'
        />
      </PageTitle>
      <div className='contentContainer'>
        <div className='contentColumn'>
          {error !== null && <Notification variant='danger'>{error}</Notification>}
          {error === null && <SalaryYearSummaryList year={year} summaries={allowedYearPeriodSummaries} />}
          <ButtonGroup className='buttonGroup'>
            <BackButton to='/salaries' visibleScreenSize='sm' />
          </ButtonGroup>
        </div>
        <div className='contentColumn' />
      </div>
    </div>
  )
}
export default SalariesYear
