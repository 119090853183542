import React, { ReactElement, useContext, useEffect } from 'react'
import { AuthContext } from '../contexts/AuthContext'
import { Navigate, useSearchParams } from 'react-router-dom'
import useAuth from '../hooks/useAuth'
import useUser from '../hooks/useUser'
import useAccount from '../hooks/useAccount'
import useAnalytics from '../hooks/useAnalytics'
import Loader from '../components/Loader'

const Redirect = (): ReactElement => {
  const { logSignIn } = useAnalytics()
  const { getAccount } = useAccount()
  const { postOidcAuth, postRegistration, error } = useAuth()
  const { getTermsOfServiceStatus, termsOfServiceStatus } = useUser()
  const { isAuth, setToken, termsAgreed, setAgreedTermsVersion, setDemo, isDemo, setDeviceId } = useContext(AuthContext)
  const [searchParams] = useSearchParams()
  const code = searchParams.get('code')
  const state = searchParams.get('state')
  const canceled = searchParams.get('error')

  const authenticate = async (code: string | null, state: string | null): Promise<void> => {
    if (code === null) {
      return
    }
    setDemo(state === 'demo')
    const deviceId = await postOidcAuth(code)
    if (deviceId === null) {
      return
    }
    setDeviceId(deviceId)
    const newToken = await postRegistration(deviceId)
    if (newToken !== null) {
      setToken(newToken)
    }
  }

  const resolveTermsOfService = async (): Promise<void> => {
    const version = await getTermsOfServiceStatus()
    setAgreedTermsVersion(version)
  }

  const handleSignIn = async (): Promise<void> => {
    const account = await getAccount()
    if (account !== null) {
      logSignIn(account)
    }
    if (!isDemo()) {
      void resolveTermsOfService()
    }
  }

  useEffect(() => {
    if (!isAuth && code !== null) {
      void authenticate(code, state)
    } else if (isAuth) {
      void handleSignIn()
    }
  }, [code, isAuth, state])

  if (error !== null || (canceled !== undefined && canceled !== null)) {
    return <Navigate to='/' state={{ error }} />
  }

  return (
    <div className='Redirect'>
      <Loader />
      {isAuth && termsAgreed && <Navigate to='/salaries' />}
      {isAuth && !termsAgreed && termsOfServiceStatus !== null && <Navigate to='/agree_terms' />}
    </div>
  )
}

export default Redirect
