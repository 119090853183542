const endpoints = {
  signOutPath: 'auth/device/logout/{deviceId}',
  pingPath: 'accounts/', // Must be such that returns new token if refreshed
  oidcPath: 'auth/oidc/v2',
  registerPath: 'auth/register',
  salariesPath: 'salaries/',
  accountsPath: 'accounts/',
  notificationsPath: 'notifications/',
  unregisterNotificationsPath: 'notifications/unregister',
  testNotificationsPath: 'notifications/test/',
  minVersionPath: 'version/client/min',
  newRegisterPath: 'auth/register/new',
  salaryDetailsPath: 'user/salarydetails',
  userSettingsPath: 'user/settings',
  sendMailPath: 'mail/hintsilta',
  newAbsencePath: 'absence',
  absencesPath: 'absences',
  sendEditTaxPath: 'tax/card',
  taxCardsPath: 'tax/cards',
  userAppsPath: 'user/apps/{companyId}',
  yearSalariesPath: 'salaries/years/{year}',
  singleSalaryPath: 'salaries/companies/{companyId}/paydays/{payday}/employments/{employmentId}',
  salaryPdfPath: 'salaries/pdf/companies/{companyId}/paydays/{payday}/employments/{employmentId}/{locale}',
  salaryConfigPath: 'salaries/config',
  termsOfServiceStatusPath: 'user/termsofservicestatus',
  termsOfServiceAgreementPath: 'user/agreetermsofservice'
}

export default endpoints
