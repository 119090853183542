import React, { ReactElement, useEffect, useMemo, useState } from 'react'
import PageTitle from '../components/PageTitle'
import useLocalization from '../hooks/useLocalization'
import useAbsences from '../hooks/useAbsences'
import Loader from '../components/Loader'
import AbsencesList from '../components/AbsencesList'
import useUser from '../hooks/useUser'
import useAccount from '../hooks/useAccount'
import Notification from '../components/Notification'
import useAnalytics from '../hooks/useAnalytics'
import { Employment } from '../types/Account'
import Icon from '../components/Icon'
import Absence from '../types/Absence'
import Button from 'react-bootstrap/Button'
import { MdOutlineAdd } from 'react-icons/md'
import AbsenceModal from '../components/AbsenceModal'
import AbsencesArchive from '../components/AbsencesArchive'
import InfoRow from '../components/InfoRow'

const Absences = (): ReactElement => {
  const [showModal, setShowModal] = useState(false)
  const [showSuccess, setShowSuccess] = useState(false)
  const { absences, getAbsences, loading, error } = useAbsences()
  const { account, getAccount, loading: loadingAccount } = useAccount()
  const { userApps, getUserApps, loading: loadingUserApps } = useUser()
  const { t } = useLocalization()
  const { logPageView } = useAnalytics()

  useEffect(() => {
    logPageView('Medical certifications')
    void getAccount().then(account => {
      if (account !== null) {
        void getUserApps(account)
      }
    })
    void getAbsences()
    // eslint-disable-next-line
  }, [])

  const handleSuccess = (): void => {
    setShowModal(false)
    setShowSuccess(true)
    void getAbsences(true)
  }

  // List employments that have medical and web app enabled
  const employments = useMemo<Employment[]>(() => (
    (account?.AccountInfo?.Employment ?? []).filter(employment => (
      employment.CompanyId !== null &&
      userApps[employment.CompanyId]?.App1 &&
      userApps[employment.CompanyId]?.App3 &&
      (
        employment.EndingDate === null ||
        employment.EndingDate.substring(0, 4) === '1900' ||
        new Date(employment.EndingDate).valueOf() > Date.now()
      )
    ))
  ), [account, userApps])

  const enabled = employments.length > 0

  const sortedAbsences = useMemo<Absence[]>(() => (
    [...(absences ?? [])].filter(absence => (
      absence.CompanyId !== null && userApps[absence.CompanyId]?.App3
    )).sort((a, b) => (
      Date.parse(a.StartDate) > Date.parse(b.StartDate) ? -1 : 1
    ))
  ), [absences, userApps])

  if (loading || loadingAccount || loadingUserApps) {
    return <Loader />
  }

  const latestYear: string | null = sortedAbsences.length > 0
    ? new Date(sortedAbsences[0].StartDate).getFullYear().toString()
    : null

  const yearSummary = (excludeLatest: boolean = false): { [year: string]: Absence[] } => {
    return (sortedAbsences ?? []).reduce((acc: { [year: string]: Absence[] }, absence: Absence) => {
      const year = new Date(absence.StartDate).getFullYear().toString()
      if (excludeLatest && year === latestYear) {
        return acc
      }
      acc[year] !== undefined ? acc[year] = [...acc[year], absence] : acc[year] = [absence]
      return acc
    }, {})
  }

  const newButton = (): ReactElement => (
    <div className='newButton'>
      {showSuccess && (
        <Notification variant='success'>
          {t('newMedicalCertification.sentDialogText')}
        </Notification>)}
      <Button
        disabled={!enabled}
        variant='outline-primary'
        onClick={() => {
          setShowSuccess(false)
          setShowModal(true)
        }}
      >
        <MdOutlineAdd className='newButtonIcon' />
        {t('medicalCertifications.sendButton')}
      </Button>
    </div>
  )

  return (
    <div className='Absences'>
      <PageTitle icon={<Icon route='absences' />}>{t('medicalCertifications.title')}</PageTitle>
      {newButton()}
      <div className='contentContainer'>
        <div className='contentColumn'>
          {error !== null && <Notification variant='danger'>{error}</Notification>}
          {!enabled && (
            <InfoRow variant='titleWithMargin' label={t('medicalCertifications.disabled')} />
          )}
          {error === null && enabled && (
            <AbsencesList
              absences={latestYear !== null ? yearSummary()[latestYear] : []}
            />
          )}
        </div>
        <div className='contentColumn'>
          {error === null && enabled && (
            <AbsencesArchive yearSummary={yearSummary(true)} />
          )}
        </div>
      </div>
      <AbsenceModal
        employments={employments}
        show={showModal}
        onSuccess={handleSuccess}
        onClose={() => setShowModal(false)}
      />
    </div>
  )
}
export default Absences
