import { ReactNode, ReactElement } from 'react'

interface Props {
  icon?: ReactElement
  children: ReactNode
}

const PageTitle = ({ icon, children }: Props): ReactElement => {
  return (
    <div className='PageTitle'>
      {icon !== undefined && (
        <div className='icon'>
          {icon}
        </div>
      )}
      <div className='title'>
        {children}
      </div>
      {icon !== undefined && (
        <div className='icon' />
      )}
    </div>
  )
}
export default PageTitle
