import React, { ReactElement } from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'

interface Props {
  name: string
  label?: string
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  checked: boolean
  error: string | null
  id?: string
}

const FormCheckbox = ({ id, error, name, checked, label, onChange }: Props): ReactElement => {
  return (
    <Row id={id}>
      <Col>
        <Form.Check type='checkbox'>
          <Form.Check.Input
            onChange={onChange}
            name={name}
            checked={checked}
            type='checkbox'
            isInvalid={error !== null}
          />
          <Form.Check.Label>{label}</Form.Check.Label>
          <Form.Control.Feedback type='invalid'>
            {error}
          </Form.Control.Feedback>
        </Form.Check>
      </Col>
    </Row>
  )
}
export default FormCheckbox
