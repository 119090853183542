import React, { ReactElement, useContext, useEffect, useState } from 'react'
import logo from '../images/siltalogo_transparent.png'
import { MdLogout } from 'react-icons/md'
import LanguageSelector from './LanguageSelector'
import { AuthContext } from '../contexts/AuthContext'
import SignOutModal from './SignOutModal'
import useAccount from '../hooks/useAccount'
import { Link } from 'react-router-dom'

const Header = (): ReactElement => {
  const { getAccount, account } = useAccount()
  const { isAuth } = useContext(AuthContext)
  const [showSignOutModal, setShowSignOutModal] = useState(false)

  useEffect(() => {
    if (isAuth) {
      void getAccount()
    }
  }, [isAuth])

  return (
    <div className={['Header', isAuth ? null : 'landing'].filter(t => t !== null).join(' ')}>
      <div className='logoContainer'>
        <img alt='logo' src={logo} className='logo' />
        <span className='title'>e&#x200A;<strong>Simo</strong></span>
        {process.env.REACT_APP_ENV !== 'production' && process.env.REACT_APP_ENV !== undefined && (
          <span className='envName'>{process.env.REACT_APP_ENV}</span>
        )}
      </div>
      <div className='signOutContainer'>
        {isAuth && (
          <Link to='/user_info' className='username'>
            {account?.AccountInfo?.PersonalData?.Name}
          </Link>
        )}
        <div className='buttonContainer'>
          <LanguageSelector />
          {isAuth && (
            <div className='iconContainer'>
              <button
                className='button'
                id='SignOutButton'
                onClick={() => setShowSignOutModal(true)}
              >
                <MdLogout className='icon' />
              </button>
            </div>
          )}
        </div>
      </div>
      <SignOutModal
        show={showSignOutModal}
        onHide={() => setShowSignOutModal(false)}
      />
    </div>
  )
}
export default Header
