import { ReactElement } from 'react'
import Absence from '../types/Absence'
import InfoRow from './InfoRow'
import useLocalization from '../hooks/useLocalization'
import AbsenceRow from './AbsenceRow'

interface Props {
  absences: Absence[]

}

const AbsencesList = ({ absences }: Props): ReactElement => {
  const { t } = useLocalization()

  return (
    <div className='AbsencesList'>
      <InfoRow
        variant='titleWithMargin'
        label={t('medicalCertifications.sentCertifications')}
      />
      {absences.length === 0 && '-'}
      {absences.map((absence, index) => (
        <AbsenceRow
          id={`absenceRow${index}`}
          key={`absenceRow${absence.Timestamp}`}
          absence={absence}
        />
      ))}
    </div>
  )
}
export default AbsencesList
