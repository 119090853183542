import { useContext, useState } from 'react'
import useApi from './useApi'
import { ResourceContext } from '../contexts/ResourceContext'
import Account, { Employment, TaxCardInfo } from '../types/Account'

interface ReturnProps {
  getAccount: () => Promise<Account | null>
  companyName: (companyId: string) => string | null
  loading: boolean
  error: string | null
  account: Account | null
  taxCard: TaxCardInfo | null
  employments: Employment[]
}

const useAccount = (): ReturnProps => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<string | null>(null)
  const { get, endpoints } = useApi()
  const { setAccount, account } = useContext(ResourceContext)

  const getAccount = async (): Promise<Account | null> => {
    if (account !== null) {
      return account
    }
    setLoading(true)
    try {
      const data = await get(endpoints.accountsPath)
      const accountData = data.Account as Account
      setAccount(accountData)
      setLoading(false)
      return accountData
    } catch (error) {
      setError(error instanceof Error ? error.message : String(error))
      setLoading(false)
      return null
    }
  }

  const companyName = (companyId: string): string | null => {
    const employments = account?.AccountInfo?.Employment
    if (!Array.isArray(employments)) {
      return null
    }
    const employment = employments.find(employment => employment.CompanyId === companyId)
    if (employment !== undefined) {
      return employment.CompanyName
    }
    return null
  }

  const taxCard = (): TaxCardInfo | null => {
    const info = account?.TaxInfo?.TaxCardInfo
    if (Array.isArray(info) && info.length > 0) {
      return info[0]
    }
    return null
  }

  const employments: Employment[] = account !== null && Array.isArray(account?.AccountInfo?.Employment)
    ? account.AccountInfo.Employment
    : []

  return {
    getAccount,
    companyName,
    loading,
    error,
    account,
    taxCard: taxCard(),
    employments
  }
}

export default useAccount
