import React, { ReactElement, useContext } from 'react'
import Dropdown from 'react-bootstrap/Dropdown'
import { MdLanguage } from 'react-icons/md'
import { LocaleContext } from '../contexts/LocaleContext'
import locales, { localeLabels } from '../config/locales'
import useLocalization from '../hooks/useLocalization'

interface ToggleProps {
  children: React.ReactNode
  onClick: (e: any) => void
}

const LanguageSelector = (): ReactElement => {
  const { t } = useLocalization()
  const { locale, setLocale } = useContext(LocaleContext)

  const CustomToggle = React.forwardRef<any, ToggleProps>(({ children, onClick }, ref): ReactElement => (
    <button
      ref={ref}
      className='button'
      title={t('settings.language')}
      onClick={(e) => {
        e.preventDefault()
        onClick(e)
      }}
    >
      {children}
    </button>
  ))

  return (
    <Dropdown className='LanguageSelector'>
      <Dropdown.Toggle variant='link' id='LanguageSelectorDropdownToggle' as={CustomToggle}>
        <div className='localeSelector'>
          <div className='icon'>
            <span className='localeLabel'>{locale}</span>
            <MdLanguage />
          </div>
        </div>
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <Dropdown.ItemText>{t('settings.language')}</Dropdown.ItemText>
        <Dropdown.Divider />
        {locales.map(localeOption => (
          <Dropdown.Item
            key={`LanguageSelectorOption${localeOption}`}
            disabled={localeOption === locale}
            onClick={() => setLocale(localeOption)}
          >
            {localeLabels[localeOption]}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  )
}
export default LanguageSelector
