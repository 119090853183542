import { ReactElement } from 'react'
import SalaryPeriodSummary from '../types/SalaryPeriodSummary'
import useLocalization from '../hooks/useLocalization'
import useFormatter from '../hooks/useFormatter'
import SalaryYearSummary from '../types/SalaryYearSummary'
import { BsChevronRight } from 'react-icons/bs'
import InfoRow from './InfoRow'
import { Link } from 'react-router-dom'

interface Props {
  periodSummaries: SalaryPeriodSummary[] | null
  yearSummaries: SalaryYearSummary[] | null
}

const SalarySummaryList = ({ periodSummaries, yearSummaries }: Props): ReactElement => {
  const { t } = useLocalization()
  const { formatDate, formatCurrency } = useFormatter()

  const resolveYearTotal = (): number | null => {
    if (yearSummaries === null) {
      return null
    }
    const currentYear = (new Date()).getFullYear().toString()
    return yearSummaries.reduce((sum, item) => (
      item.Year === currentYear ? sum + parseFloat(item.Total) : sum
    ), 0)
  }

  const resolveNextSalary = (): SalaryPeriodSummary | null => {
    if (periodSummaries === null || periodSummaries.length === 0) {
      return null
    }
    const sorted = periodSummaries.sort((a, b) =>
      new Date(b.Payday).valueOf() - new Date(a.Payday).valueOf()
    )
    return sorted[0]
  }

  const resolvePeriodSummaries = (): SalaryPeriodSummary[] => {
    if (periodSummaries === null || periodSummaries.length === 0) {
      return []
    }
    return periodSummaries.sort((a, b) =>
      new Date(b.Payday).valueOf() - new Date(a.Payday).valueOf()
    ).filter((item, i) => i !== 0)
  }

  const salaryPath = (salary: SalaryPeriodSummary): string => (
    `/salaries/${salary.CompanyId}/${salary.EmploymentId}/${salary.Payday}`
  )

  const nextSalary = resolveNextSalary()

  return (
    <div className='SalaryPeriodSummaryList'>
      <InfoRow
        label={t('mainSalary.fromStartOfYear')}
        variant='title'
        value={formatCurrency(resolveYearTotal())}
      />
      {nextSalary !== null && (
        <Link
          id='NextSalaryLink'
          className='nextSalary'
          to={salaryPath(nextSalary)}
        >
          <div className='monthAndYear'>
            {formatDate(nextSalary.Payday, 'monthAndYear')}
          </div>
          <div className='salaryTotalContainer'>
            <div />
            <div>
              <div className='salaryTotal'>{formatCurrency(nextSalary.Total)}</div>
              <div className='salaryTotalPayday'>
                {new Date(nextSalary.Payday).valueOf() < Date.now()
                  ? t('mainSalary.wasPaid')
                  : t('mainSalary.toBePaid')}
                &nbsp;{formatDate(nextSalary.Payday, 'dateOnly')}
              </div>
            </div>
            <div className='arrow'>
              <BsChevronRight />
            </div>
          </div>
          <div className='companyName'>
            {nextSalary.CompanyName}
          </div>
        </Link>
      )}
      {resolvePeriodSummaries().map((summary, i) => (
        <InfoRow
          id={`periodSummaryRow${i}`}
          key={`periodSummaryRow${i}`}
          label={formatDate(summary.Payday, 'monthAndYear')}
          subLabel={summary.CompanyName}
          value={formatCurrency(summary.Total)}
          linkTo={salaryPath(summary)}
        />
      ))}
    </div>
  )
}
export default SalarySummaryList
