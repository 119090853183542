import React, { useState } from 'react'

type inputEvent = React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>

const useForm = <T>(initialState: T): {
  form: T
  handleInputChange: (event: inputEvent) => void
  handleDateChange: (name: string, date: Date | null) => void
  resetForm: (state?: T) => void
} => {
  const [formState, setFormState] = useState<T>(initialState)

  const handleInputChange = (event: inputEvent): void => {
    const target = event.target
    let value
    if (target instanceof HTMLInputElement) {
      if (target.files !== null && target.files.length > 0) {
        const reader = new FileReader()
        const file = target.files[0]
        reader.readAsDataURL(file)
        reader.onload = () => {
          const parts = (reader.result?.toString() ?? '').split(',')
          let base64: string
          if (parts.length === 2) {
            base64 = parts[1]
          } else {
            console.log('Invalid base64 format: ', reader.result)
            return
          }
          setFormState((form) => ({
            ...form,
            [`${target.name}Base64`]: base64,
            [`${target.name}Filename`]: file.name
          }))
        }
        return
      } else {
        value = target.type === 'checkbox' ? target.checked : target.value
      }
    } else {
      value = target.value
    }
    setFormState({ ...formState, [target.name]: value })
  }

  const handleDateChange = (name: string, date: Date | null): void => {
    setFormState({ ...formState, [name]: date })
  }

  const resetForm = (state?: T): void => {
    setFormState(state ?? initialState)
  }

  return {
    form: formState,
    handleInputChange,
    handleDateChange,
    resetForm
  }
}

export default useForm
