import React, { ReactElement, useState } from 'react'
import { Button } from 'react-bootstrap'
import { MdOutlineMoreHoriz } from 'react-icons/md'
import { Link, useLocation } from 'react-router-dom'
import Offcanvas from 'react-bootstrap/Offcanvas'
import useLocalization from '../hooks/useLocalization'
import Icon from './Icon'

interface Props {
  onCookieInfoClick: () => void
}

const Menu = ({ onCookieInfoClick }: Props): ReactElement => {
  const [showSideMenu, setShowSideMenu] = useState(false)
  const { pathname } = useLocation()
  const { t } = useLocalization()

  const resolveClass = (page: string | string[], customClass?: string): string => {
    if (!Array.isArray(page)) {
      page = [page]
    }
    const classes = ['item', ...page.map(p => p.replaceAll('/', ''))]
    if (page.some(p => pathname.includes(p))) {
      classes.push('selected')
    }
    if (customClass !== undefined) {
      classes.push(customClass)
    }
    return classes.join(' ')
  }

  const sideMenu = (): ReactElement => (
    <Offcanvas
      className='SideMenu'
      show={showSideMenu}
      onHide={() => setShowSideMenu(false)}
    >
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>{t('other.title')}</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <nav>
          <Link
            onClick={() => setShowSideMenu(false)}
            className={resolveClass('/absences')}
            to='absences'
          >
            <span className='text'>{t('tabs.medicalCertifications')}</span>
            <Icon route='absences' className='icon' />
          </Link>
          <Link
            onClick={() => setShowSideMenu(false)}
            className={resolveClass('/terms')}
            to='terms'
          >
            <span className='text'>{t('other.terms')}</span>
            <Icon route='terms' className='icon terms' />
          </Link>
          <Button
            variant='link'
            onClick={() => {
              onCookieInfoClick()
              setShowSideMenu(false)
            }}
            className={resolveClass('/xxx')}
          >
            <span className='text'>{t('other.cookiePolicy')}</span>
            <Icon route='cookieInfo' className='icon terms' />
          </Button>
          <a
            className={resolveClass('/privacyStatement')}
            href='https://www.silta.fi/tietosuojaseloste-simo'
            target='_blank'
            rel='noreferrer'
          >
            <span className='text'>{t('other.privacyStatement')}</span>
            <Icon route='privacyStatement' className='icon privacyStatement' />
          </a>
        </nav>
      </Offcanvas.Body>
    </Offcanvas>
  )

  return (
    <div className='Menu'>
      <div className='divider' />
      <nav>
        <Link className={resolveClass('/user_info')} to='user_info'>
          <span className='text'>{t('tabs.ownInformation')}</span>
          <Icon route='userInfo' className='icon' />
        </Link>
        <Link className={resolveClass('/salaries')} to='salaries'>
          <span className='text'>{t('tabs.salary')}</span>
          <Icon route='salaries' className='icon' />
        </Link>
        <Link className={resolveClass('/tax_info')} to='tax_info'>
          <span className='text'>{t('tabs.taxInformation')}</span>
          <Icon route='taxInfo' className='icon' />
        </Link>
        <Link className={resolveClass('/absences', 'hideInSmall')} to='absences'>
          <span className='text'>{t('tabs.medicalCertifications')}</span>
          <Icon route='absences' className='icon' />
        </Link>
        <div
          className={resolveClass(['/absences', '/terms'], 'onlyInSmall')}
          onClick={() => setShowSideMenu(!showSideMenu)}
        >
          <span className='text'>{t('other.title')}</span>
          <MdOutlineMoreHoriz className='icon' />
        </div>
        {sideMenu()}
      </nav>
    </div>
  )
}
export default Menu
