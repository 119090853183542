import React, { createContext, useState } from 'react'
import Absence from '../types/Absence'
import Account from '../types/Account'
import SalaryPeriodSummary from '../types/SalaryPeriodSummary'
import SalaryYearSummary from '../types/SalaryYearSummary'
import SalaryDetails from '../types/SalaryDetails'
import TaxCard from '../types/TaxCard'
import UserApps from '../types/UserApps'
import TermsOfServiceStatus from '../types/TermsOfServiceStatus'
import SalaryConfig from '../types/SalaryConfig'

interface Context {
  userApps: {[companyId: string]: UserApps}
  absences: Absence[] | null
  account: Account | null
  salaryConfig: SalaryConfig | null
  salaryPeriodSummaries: SalaryPeriodSummary[] | null
  salaryYearSummaries: SalaryYearSummary[] | null
  salaryYearPeriodSummaries: {[year: string]: SalaryPeriodSummary[]}
  salaryDetails: {[key: string]: SalaryDetails}
  taxCards: TaxCard[] | null
  termsOfServiceStatus: TermsOfServiceStatus | null
  setUserApps: (apps: {[companyId: string]: UserApps}) => void
  setAbsences: (absences: Absence[]) => void
  setAccount: (account: Account) => void
  setSalaryPeriodSummaries: (summaries: SalaryPeriodSummary[]) => void
  setSalaryYearSummaries: (summaries: SalaryYearSummary[]) => void
  setSalaryYearPeriodSummaries: (summaries: {[year: string]: SalaryPeriodSummary[]}) => void
  setSalaryDetails: (details: {[key: string]: SalaryDetails}) => void
  setTaxCards: (taxCards: TaxCard[]) => void
  setTermsOfServiceStatus: (status: TermsOfServiceStatus) => void
  setSalaryConfig: (config: SalaryConfig) => void
  resetResources: () => void
}

const defaultValues = {
  userApps: {},
  absences: null,
  account: null,
  salaryConfig: null,
  salaryPeriodSummaries: null,
  salaryYearSummaries: null,
  salaryYearPeriodSummaries: {},
  salaryDetails: {},
  taxCards: null,
  termsOfServiceStatus: null,
  setUserApps: () => {},
  setAbsences: () => {},
  setAccount: () => {},
  setSalaryPeriodSummaries: () => {},
  setSalaryYearSummaries: () => {},
  setSalaryYearPeriodSummaries: () => {},
  setSalaryDetails: () => {},
  setTaxCards: () => {},
  setTermsOfServiceStatus: () => {},
  setSalaryConfig: () => {},
  resetResources: () => {}
}

export const ResourceContext = createContext<Context>(defaultValues)

export const ResourceProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [absences, setAbsences] = useState<Context['absences']>(defaultValues.absences)
  const [account, setAccount] = useState<Context['account']>(defaultValues.account)
  const [salaryPeriodSummaries, setSalaryPeriodSummaries] =
    useState<Context['salaryPeriodSummaries']>(defaultValues.salaryPeriodSummaries)
  const [salaryYearSummaries, setSalaryYearSummaries] =
    useState<Context['salaryYearSummaries']>(defaultValues.salaryYearSummaries)
  const [salaryYearPeriodSummaries, setSalaryYearPeriodSummaries] =
    useState<Context['salaryYearPeriodSummaries']>(defaultValues.salaryYearPeriodSummaries)
  const [salaryDetails, setSalaryDetails] =
    useState<Context['salaryDetails']>(defaultValues.salaryDetails)
  const [taxCards, setTaxCards] = useState<Context['taxCards']>(defaultValues.taxCards)
  const [userApps, setUserApps] = useState<Context['userApps']>(defaultValues.userApps)
  const [termsOfServiceStatus, setTermsOfServiceStatus] = useState<Context['termsOfServiceStatus']>(defaultValues.termsOfServiceStatus)
  const [salaryConfig, setSalaryConfig] = useState<Context['salaryConfig']>(defaultValues.salaryConfig)

  const resetResources = (): void => {
    setAbsences(defaultValues.absences)
    setAccount(defaultValues.account)
    setSalaryPeriodSummaries(defaultValues.salaryPeriodSummaries)
    setSalaryYearSummaries(defaultValues.salaryYearSummaries)
    setSalaryYearPeriodSummaries(defaultValues.salaryYearPeriodSummaries)
    setSalaryDetails(defaultValues.salaryDetails)
    setTaxCards(defaultValues.taxCards)
    setUserApps(defaultValues.userApps)
    setTermsOfServiceStatus(defaultValues.termsOfServiceStatus)
    setSalaryConfig(defaultValues.salaryConfig)
  }

  return (
    <ResourceContext.Provider
      value={{
        absences,
        account,
        salaryConfig,
        salaryPeriodSummaries,
        salaryYearPeriodSummaries,
        salaryYearSummaries,
        salaryDetails,
        taxCards,
        userApps,
        termsOfServiceStatus,
        setAbsences,
        setAccount,
        setSalaryConfig,
        setSalaryPeriodSummaries,
        setSalaryYearSummaries,
        setSalaryYearPeriodSummaries,
        setSalaryDetails,
        setTaxCards,
        setUserApps,
        setTermsOfServiceStatus,
        resetResources
      }}
    >
      {children}
    </ResourceContext.Provider>
  )
}
