import React, { createContext, useState, useRef } from 'react'
import useStorage from '../hooks/useStorage'
import versions from '../config/versions'

interface Context {
  isAuth: boolean
  termsAgreed: boolean
  token: () => string | null
  deviceId: string | null
  lastRequestTime: () => number | null
  setToken: (token: string | null) => void
  setDeviceId: (deviceId: string | null) => void
  setAgreedTermsVersion: (version: number | null) => void
  isDemo: () => boolean
  setDemo: (demo: boolean) => void
  oidcAuthInProgress: () => boolean
  setOidcAuthStatus: (inProgress: boolean) => void
  signOut: () => void
  refreshRequestTime: () => void
}

const defaultValues = {
  termsAgreed: false,
  isAuth: false,
  token: () => null,
  deviceId: null,
  lastRequestTime: () => null,
  setToken: () => {},
  setDeviceId: () => {},
  setAgreedTermsVersion: () => {},
  isDemo: () => false,
  setDemo: () => {},
  oidcAuthInProgress: () => false,
  setOidcAuthStatus: () => {},
  signOut: () => {},
  refreshRequestTime: () => {}
}

export const AuthContext = createContext<Context>(defaultValues)

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const storage = useStorage()
  const lastRequestTimeRef = useRef<number | null>(null)
  const tokenRef = useRef<string | null>(storage.getToken())
  const [isAuth, setAuth] = useState<boolean>(storage.getToken() !== null)
  const [deviceId, _setDeviceId] = useState<Context['deviceId']>(storage.getDeviceId())
  const [agreedTermsVersion, _setAgreedTermsVersion] = useState<number | null>(storage.getAgreedTermsVersion())
  const isDemoRef = useRef<boolean>(storage.getDemo())
  const oidcAuthInProgressRef = useRef<boolean>(false)

  const setDeviceId = (deviceId: string | null): void => {
    _setDeviceId(deviceId)
    storage.setDeviceId(deviceId)
  }

  const setToken = (token: string | null): void => {
    tokenRef.current = token
    setAuth(token !== null)
    storage.setToken(token)
  }

  const setAgreedTermsVersion = (version: number | null): void => {
    _setAgreedTermsVersion(version)
    storage.setAgreedTermsVersion(version)
  }

  const setDemo = (demo: boolean): void => {
    storage.setDemo(demo)
    isDemoRef.current = demo
  }

  const setOidcAuthStatus = (inProgress: boolean): void => {
    oidcAuthInProgressRef.current = inProgress
  }

  const signOut = (): void => {
    lastRequestTimeRef.current = null
    setToken(null)
    setDeviceId(null)
    setAgreedTermsVersion(null)
    setDemo(false)
  }

  const refreshRequestTime = (): void => {
    lastRequestTimeRef.current = Date.now()
  }

  return (
    <AuthContext.Provider
      value={{
        isAuth,
        token: () => tokenRef.current,
        deviceId,
        termsAgreed: isDemoRef.current || agreedTermsVersion === versions.termsOfService,
        lastRequestTime: () => lastRequestTimeRef.current,
        setToken,
        setDeviceId,
        setAgreedTermsVersion,
        isDemo: () => isDemoRef.current, // Use function here for more reliable current status fetch
        setDemo,
        setOidcAuthStatus,
        oidcAuthInProgress: () => oidcAuthInProgressRef.current,
        signOut,
        refreshRequestTime
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}
