import { ReactElement } from 'react'
import InfoRow from './InfoRow'
import Expandable from './Expandable'
import Absence from '../types/Absence'
import useLocalization from '../hooks/useLocalization'
import AbsenceRow from './AbsenceRow'

interface Props {
  yearSummary: { [year: string]: Absence[] }
}

const AbsencesArchive = ({ yearSummary }: Props): ReactElement => {
  const { t } = useLocalization()

  return (
    <div className='AbsencesArchive'>
      {Object.keys(yearSummary).length > 0 && (
        <InfoRow
          variant='titleWithMargin'
          label={t('medicalCertifications.archive')}
        />
      )}
      {Object.keys(yearSummary).length === 0 && '-'}
      {Object.keys(yearSummary).reverse().map(year => (
        <Expandable
          id={`absenceYear${year}`}
          key={`absenceYear${year}`}
          title={year}
          subtitle={`${yearSummary[year].length} ${t('medicalCertifications.pcs')}`}
        >
          {yearSummary[year].map((absence, i) => (
            <AbsenceRow
              id={`absenceYear${year}Row${i}`}
              key={`absenceYear${year}Row${absence.Timestamp}`}
              absence={absence}
            />
          ))}
        </Expandable>
      ))}
    </div>
  )
}
export default AbsencesArchive
