import React, { ReactElement } from 'react'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import useLocalization from '../hooks/useLocalization'
import aktiaLogo from '../images/aktia.png'
import alandsbankenLogo from '../images/alandsbanken.png'
import danskebankLogo from '../images/danskebank.png'
import handelsbankenLogo from '../images/handelsbanken.png'
import nordeaLogo from '../images/nordea.png'
import omasaastopankkiLogo from '../images/omasaastopankki.png'
import osuuspankkiLogo from '../images/osuuspankki.png'
import poppankkiLogo from '../images/poppankki.png'
import saastopankkiLogo from '../images/saastopankki.png'
import spankkiLogo from '../images/spankki.png'
import mobiilivarmenneLogo from '../images/mobiilivarmenne.png'
import registrationMethods from '../config/registrationMethods'
import useAuthProvider from '../hooks/useAuthProvider'
import useAnalytics from '../hooks/useAnalytics'

const logos = {
  MOBIILIVARMENNE: mobiilivarmenneLogo,
  AKTIA: aktiaLogo,
  ALANDSBANKEN: alandsbankenLogo,
  HANDELSBANKEN: handelsbankenLogo,
  NORDEA: nordeaLogo,
  OMASAASTOPANKKI: omasaastopankkiLogo,
  OSUUSPANKKI: osuuspankkiLogo,
  DANSKEBANK: danskebankLogo,
  SPANKKI: spankkiLogo,
  POPPANKKI: poppankkiLogo,
  SAASTOPANKKI: saastopankkiLogo
}

const titles = {
  MOBIILIVARMENNE: 'Mobiilivarmenne',
  AKTIA: 'Aktia',
  ALANDSBANKEN: 'Ålandsbanken',
  HANDELSBANKEN: 'Handelsbanken',
  NORDEA: 'Nordea',
  OMASAASTOPANKKI: 'Oma Säästöpankki',
  OSUUSPANKKI: 'Osuuspankki',
  DANSKEBANK: 'Danske Bank',
  SPANKKI: 'S-Pankki',
  POPPANKKI: 'POP Pankki',
  SAASTOPANKKI: 'Säästöpankki'
}

interface Props {
  show: boolean
  onHide: () => void
}

const AuthMethodModal = ({ show, onHide }: Props): ReactElement => {
  const { t } = useLocalization()
  const { linkToAuthMethod } = useAuthProvider()
  const { logAuthMethodUsed } = useAnalytics()

  const handleAuthMethodSelect = (method: string): void => {
    logAuthMethodUsed(method)
  }

  return (
    <Modal show={show} onHide={onHide} className='AuthMethodModal'>
      <Modal.Header closeButton>
        <Modal.Title>{t('selectMethod.welcomeHeader')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='intro'>{t('selectMethod.welcomeText')}</div>
        <div className='methods'>
          {registrationMethods.map(method => (
            <a
              title={titles[method]}
              id={`Method${method}`}
              key={`Method${method}`}
              href={linkToAuthMethod(method)}
              onClick={() => handleAuthMethodSelect(method)}
            >
              <img
                id={`MethodImage${method}`}
                key={`MethodImage${method}`}
                src={logos[method]}
                alt={method}
              />
            </a>
          ))}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant='secondary' onClick={onHide}>
          {t('signOutModal.cancel')}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
export default AuthMethodModal
