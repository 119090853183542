import { useContext } from 'react'
import { FirebaseApp, initializeApp } from 'firebase/app'
import { initializeAnalytics, getAnalytics, logEvent, Analytics, setConsent } from 'firebase/analytics'
import firebaseConfig from '../config/firebaseConfig'
import versions from '../config/versions'
import { LocaleContext } from '../contexts/LocaleContext'
import Account from '../types/Account'
import { AnalyticsContext } from '../contexts/AnalyticsContext'
import useAccount from './useAccount'

interface ReturnProps {
  cookieConsent: boolean | null
  setCookieConsent: (consent: boolean) => void
  logSignIn: (account: Account) => void
  logTermsAgreed: () => void
  logTermsNotAgreed: () => void
  logPageView: (pageTitle: string) => void
  logAbsencePost: () => void
  logHintMailSent: () => void
  logAuthMethodUsed: (authMethod: string) => void
}

const useAnalytics = (): ReturnProps => {
  const {
    app,
    setApp,
    userProperties,
    setUserProperties,
    cookieConsent, setCookieConsent: contextSetCookieConsent
  } = useContext(AnalyticsContext)
  const { locale } = useContext(LocaleContext)
  const { account } = useAccount()

  const initAnalytics = (): FirebaseApp | null => {
    if (firebaseConfig === null) {
      return null
    }
    try {
      const app = initializeApp(firebaseConfig)
      initializeAnalytics(app, { config: { send_page_view: false } })
      if (cookieConsent !== null) {
        setFirebaseConsent(cookieConsent)
      }
      setApp(app)
      return app
    } catch (e) {
      console.error('Error initializing analytics', e)
      return null
    }
  }

  const setFirebaseConsent = (consent: boolean): void => {
    setConsent({
      ad_storage: 'denied',
      analytics_storage: consent ? 'granted' : 'denied',
      functionality_storage: 'denied',
      personalization_storage: 'denied',
      security_storage: 'denied'
    })
  }

  const setCookieConsent = (consent: boolean): void => {
    contextSetCookieConsent(consent)
    if (app !== null) {
      setFirebaseConsent(consent)
    }
  }

  const setUserAccount = (analytics: Analytics, account: Account): void => {
    let companyNames: string[] = []
    if (Array.isArray(account?.AccountInfo?.Employment)) {
      companyNames = account.AccountInfo.Employment.map(e => {
        if (e.EndingDate === null || e.EndingDate === '1900-01-01T00:00:00') {
          return e.CompanyName
        } else {
          return null
        }
      }).filter(name => name !== null) as string[]
    }
    setUserProperties(
      analytics,
      { companies: companyNames, locale },
      account?.AccountInfo?.PersonalData?.Id
    )
  }

  const log = (eventName: string, eventParams?: any, _account?: Account): void => {
    if (firebaseConfig === null) {
      return
    }
    let analytics: Analytics | null = null
    const accountResolved = _account !== undefined ? _account : account
    if (app === null) {
      const newApp = initAnalytics()
      if (newApp !== null) {
        analytics = getAnalytics(newApp)
      }
    } else {
      analytics = getAnalytics(app)
    }
    if (analytics === null) {
      console.error('Unable to resolve analytics')
      return
    }
    if (accountResolved !== null && userProperties === null) {
      setUserAccount(analytics, accountResolved)
    }
    // console.log(`Logging event: ${eventName}`, eventParams)
    logEvent(analytics, eventName, eventParams)
  }

  const logAuthMethodUsed = (authMethod: string): void => {
    log('auth_method_used', { auth_method: authMethod })
  }

  const logTermsAgreed = (): void => {
    log('terms_agreed', { terms_version: versions.termsOfService })
  }

  const logTermsNotAgreed = (): void => {
    log('terms_not_agreed', { terms_version: versions.termsOfService })
  }

  const logPageView = (pageTitle: string): void => {
    log('screen_view', { page_title: pageTitle, screen_name: pageTitle })
  }

  const logAbsencePost = (): void => {
    log('absence_post')
  }

  const logHintMailSent = (): void => {
    log('hint_mail_sent')
  }

  const logSignIn = (account: Account): void => {
    log('login', { method: 'Signicat' }, account)
  }

  return {
    cookieConsent,
    setCookieConsent,
    logSignIn,
    logTermsAgreed,
    logTermsNotAgreed,
    logPageView,
    logAbsencePost,
    logHintMailSent,
    logAuthMethodUsed
  }
}
export default useAnalytics
