import React, { ReactElement, useEffect, useMemo } from 'react'
import { useParams, Link } from 'react-router-dom'
import PageTitle from '../components/PageTitle'
import useSalaries from '../hooks/useSalaries'
import useLocalization from '../hooks/useLocalization'
import Loader from '../components/Loader'
import useFormatter from '../hooks/useFormatter'
import Notification from '../components/Notification'
import BackButton from '../components/BackButton'
import SalaryDetailsList from '../components/SalaryDetailsList'
import SalaryPeriodSummary from '../types/SalaryPeriodSummary'
import { ButtonGroup } from 'react-bootstrap'
import useAnalytics from '../hooks/useAnalytics'
import useUser from '../hooks/useUser'
import useAccount from '../hooks/useAccount'

const Salary = (): ReactElement => {
  const { logPageView } = useAnalytics()
  const { year, companyId, employmentId, payday } = useParams()
  const { userApps, getUserApps, loading: loadingUserApps } = useUser()
  const { getAccount, loading: loadingAccount } = useAccount()
  const {
    getPdf,
    details,
    getConfig,
    getDetails,
    getSummaries,
    getYearPeriodSummaries,
    yearPeriodSummaries,
    periodSummaries,
    loading,
    loadingPdf,
    error
  } = useSalaries()
  const { t } = useLocalization()
  const { formatDate } = useFormatter()

  useEffect(() => {
    logPageView('Salary')
    void getConfig()
    void getAccount().then(account => {
      if (account !== null) {
        void getUserApps(account)
      }
    })
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (
      companyId !== undefined &&
      payday !== undefined &&
      employmentId !== undefined &&
      userApps[companyId]?.App3 === true
    ) {
      void getDetails(companyId, payday, employmentId).then(() => {
        if (year !== undefined) {
          void getYearPeriodSummaries(parseInt(year, 10))
        } else {
          void getSummaries()
        }
      })
    }
    // eslint-disable-next-line
  }, [companyId, payday, employmentId, year, userApps])

  const handleDownloadPdf = (): void => {
    void getPdf(companyId, payday, employmentId)
  }

  const summary = useMemo<SalaryPeriodSummary | null>(() => (
    (year !== undefined ? yearPeriodSummaries : periodSummaries)?.find(summary => (
      companyId !== undefined &&
      userApps[companyId]?.App3 === true &&
      summary.Payday === payday &&
      summary.CompanyId === companyId &&
      summary.EmploymentId === employmentId
    )) ?? null
  ), [year, yearPeriodSummaries, periodSummaries, userApps, companyId, payday, employmentId])

  if (loading || loadingAccount || loadingUserApps) {
    return <Loader />
  }

  return (
    <div className='Salary'>
      <PageTitle>
        {year !== undefined && (
          <div>
            <Link to='/salaries'>{t('mainSalary.title')}</Link>
            {' - '}
            <Link to={`/salaries/${year}`}>{year}</Link>
            {' - '}
            {formatDate(details?.Payday ?? null, 'monthOnly')}
          </div>
        )}
        {year === undefined && (
          <div>
            <Link to='/salaries'>{t('mainSalary.title')}</Link>
            {' - '}
            {formatDate(details?.Payday ?? null, 'monthAndYear')}
          </div>
        )}
        <BackButton
          to={year !== undefined ? `/salaries/${year}` : '/salaries'}
          size='sm'
          visibleScreenSize='md'
        />
      </PageTitle>
      <div className='content'>
        {error !== null && <Notification variant='danger'>{error}</Notification>}
        {details !== null && summary !== null && (
          <SalaryDetailsList
            loadingPdf={loadingPdf}
            onDownloadPdf={handleDownloadPdf}
            salary={details}
            summary={summary}
          />
        )}
        <ButtonGroup className='buttonGroup'>
          <BackButton
            to={year !== undefined ? `/salaries/${year}` : '/salaries'}
            visibleScreenSize='sm'
          />
        </ButtonGroup>
      </div>
    </div>
  )
}
export default Salary
