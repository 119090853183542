import { ReactElement } from 'react'
import SalaryPeriodSummary from '../types/SalaryPeriodSummary'
import InfoRow from './InfoRow'
import useFormatter from '../hooks/useFormatter'
import useLocalization from '../hooks/useLocalization'

interface Props {
  year?: string
  summaries: SalaryPeriodSummary[] | null
}

const SalaryYearSummaryList = ({ summaries, year }: Props): ReactElement => {
  const { formatDate, formatCurrency } = useFormatter()
  const { t } = useLocalization()

  const resolveSummaries = (): SalaryPeriodSummary[] => (
    (summaries ?? []).sort((a, b) => (
      new Date(a.Payday).valueOf() - new Date(b.Payday).valueOf()
    ))
  )

  return (
    <div className='SalaryYearSummaryList'>
      {resolveSummaries().length === 0 && (
        <p>{t('mainSalary.noSalarySent')}</p>
      )}
      {resolveSummaries().map((summary, i) => (
        <InfoRow
          id={`yearSummaryRow${i}`}
          key={`yearSummaryRow${i}`}
          label={formatDate(summary.Payday, 'monthAndYear')}
          subLabel={summary.CompanyName}
          value={formatCurrency(summary.Total)}
          linkTo={`/salaries/${year ?? ''}/${summary.CompanyId}/${summary.EmploymentId}/${summary.Payday}`}
        />
      ))}
    </div>
  )
}
export default SalaryYearSummaryList
