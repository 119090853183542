const apiConfig = {
  mock: {
    useMockApi: true,
    mockApiResponseDelay: 1200, // in ms
    apiUrl: null,
    tryOutUrl: null,
    tokenRefreshInterval: 60 // in s
  },
  dev: {
    useMockApi: false,
    mockApiResponseDelay: 0,
    apiUrl: process.env.REACT_APP_DEV_API_URL, // Use env.local to set local gateway ip, e.g. REACT_APP_DEV_API_URL="http://192.168.1.137:8100/api"
    tryOutUrl: null,
    tokenRefreshInterval: 185
  },
  test: {
    useMockApi: false,
    mockApiResponseDelay: 0,
    apiUrl: 'http://localhost/api',
    tryOutUrl: 'http://localhost.demo/api',
    tokenRefreshInterval: 60
  },
  staging: {
    useMockApi: false,
    mockApiResponseDelay: 0,
    apiUrl: 'https://silta-mobile-test.silta.fi/api',
    tryOutUrl: 'https://silta-mobile-demo.silta.fi/api',
    tokenRefreshInterval: 185
  },
  production: {
    useMockApi: false,
    mockApiResponseDelay: 0,
    apiUrl: 'https://silta-mobiili.silta.fi/api',
    tryOutUrl: 'https://silta-mobile-demo.silta.fi/api',
    tokenRefreshInterval: 185 // After 3 minutes token can be refreshed
  }
}

const env = process.env.REACT_APP_API_ENV as 'dev' | 'staging' | 'production'
export default apiConfig[process.env.REACT_APP_ENV === 'test' ? 'test' : env]
