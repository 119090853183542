import React, { createContext, useState } from 'react'
import locales from '../config/locales'
import useStorage from '../hooks/useStorage'
import localeFI from '../locales/fi.json'
import localeSV from '../locales/sv.json'
import localeEN from '../locales/en.json'

type localeKey = typeof locales[number]

const translationsMap = {
  fi: localeFI,
  sv: localeSV,
  en: localeEN
}

interface Context {
  translations: any
  locale: localeKey
  setLocale: (locale: localeKey) => void
}

const defaultValues = {
  translations: {},
  locale: 'fi' as const,
  setLocale: () => {}
}

export const LocaleContext = createContext<Context>(defaultValues)

export const LocaleProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const storage = useStorage()
  const defaultLocale = storage.getLocale() as localeKey ?? defaultValues.locale
  const [translations, setTranslations] = useState<any>(translationsMap[defaultLocale])
  const [locale, _setLocale] = useState<localeKey>(defaultLocale)

  const setLocale = (locale: localeKey): void => {
    _setLocale(locale)
    storage.setLocale(locale)
    setTranslations(translationsMap[locale])
  }

  return (
    <LocaleContext.Provider
      value={{
        translations,
        locale,
        setLocale
      }}
    >
      {children}
    </LocaleContext.Provider>
  )
}
