import React, { ReactElement, useEffect } from 'react'
import PageTitle from '../components/PageTitle'
import useLocalization from '../hooks/useLocalization'
import Loader from '../components/Loader'
import UserInfoCard from '../components/UserInfoCard'
import useAccount from '../hooks/useAccount'
import Notification from '../components/Notification'
import useAnalytics from '../hooks/useAnalytics'
import Icon from '../components/Icon'
import UserInfoEmployments from '../components/UserInfoEmployments'
import useUser from '../hooks/useUser'

const UserInfo = (): ReactElement => {
  const { logPageView } = useAnalytics()
  const { getUserApps, userApps } = useUser()
  const { getAccount, loading, account, error } = useAccount()
  const { t } = useLocalization()

  useEffect(() => {
    logPageView('Personal information')
    void getAccount().then(account => {
      if (account !== null) {
        void getUserApps(account)
      }
    })
    // eslint-disable-next-line
  }, [])

  if (loading) {
    return <Loader />
  }

  return (
    <div className='UserInfo'>
      <PageTitle icon={<Icon route='userInfo' />}>{t('ownInformation.title')}</PageTitle>
      <div className='contentContainer'>
        <div className='contentColumn'>
          {error !== null && <Notification variant='danger'>{error}</Notification>}
          <UserInfoCard account={account} />
        </div>
        <div className='contentColumn'>
          <UserInfoEmployments account={account} userApps={userApps} />
        </div>
      </div>
    </div>
  )
}
export default UserInfo
