import { ReactElement, useMemo } from 'react'
import Account, { Employment } from '../types/Account'
import Expandable from './Expandable'
import InfoRow from './InfoRow'
import useFormatter from '../hooks/useFormatter'
import useLocalization from '../hooks/useLocalization'
import UserApps from '../types/UserApps'

interface Props {
  account: Account | null
  userApps: {[companyId: string]: UserApps}
}

const UserInfoEmployments = ({ account, userApps }: Props): ReactElement => {
  const { t, tExists } = useLocalization()
  const { formatDate, formatCurrency } = useFormatter()

  const translateKey = (key: string): string => {
    const k = `ownInformation.translations.${key.replaceAll(' ', '')}`
    if (tExists(k)) {
      return t(k)
    } else {
      return key
    }
  }

  const valueWithUnit = (value: string | null, unit: string | null): string => {
    if (value === null) {
      return ''
    } else if (unit === null) {
      return value
    }
    switch (unit.toLowerCase()) {
      case 'pv':
        return `${parseInt(value, 10).toString()} ${t('ownInformation.days')}`
      case 'h':
        return `${value.replace('.', ',')} ${t('ownInformation.hours')}`
      case 'euro':
      case '€':
        return formatCurrency(value)
      default:
        return `${value} ${unit}`
    }
  }

  // List employments that have web app enabled
  const employments = useMemo<Employment[]>(() => (
    (account?.AccountInfo?.Employment ?? []).filter(employment => (
      employment.CompanyId !== null && userApps[employment.CompanyId]?.App3
    ))
  ), [account, userApps])

  const employmentSection = (employment: Employment, index: number): ReactElement => (
    <Expandable
      id={`employment${index}`}
      key={`employment${index}`}
      title={employment.CompanyName}
    >
      <InfoRow
        id={`employment${index}Title`}
        variant='boldTitle'
        label={employment.Title}
        value={[
          formatDate(employment.StartingDate, 'dateOnly'),
          '-',
          formatDate(employment.EndingDate, 'dateOnly')
        ].join(' ')}
      />
      {Array.isArray(employment.Salary) && employment.Salary.length > 0 &&
        <InfoRow
          variant='title'
          label={t('ownInformation.monetarySalary')}
        />}
      {employment.Salary?.map?.((item, i) => (
        item.Key !== null && item.Value !== null && parseInt(item.Value, 10) !== 0 &&
          <InfoRow
            id={`employment${index}SalaryRow${i}`}
            key={`employment${index}SalaryRow${i}`}
            label={translateKey(item.Key)}
            value={formatCurrency(item.Value)}
          />
      ))}
      {Array.isArray(employment.Vacation) && employment.Vacation.length > 0 &&
        <InfoRow
          id={`employment${index}VacationTitle`}
          variant='titleWithMargin'
          label={t('ownInformation.vacation')}
        />}
      {employment.Vacation?.map?.((item, i) => (
        item.Key !== null && item.Value !== null &&
          <InfoRow
            id={`employment${index}VacationRow${i}`}
            key={`employment${index}VacationRow${i}`}
            label={translateKey(item.Key)}
            value={valueWithUnit(item.Value, item.Unit)}
          />
      ))}
      {Array.isArray(employment.Accumulation) && employment.Accumulation.length > 0 &&
        <InfoRow
          id={`employment${index}AccumulationTitle`}
          variant='titleWithMargin'
          label={t('ownInformation.accumulation')}
        />}
      {employment.Accumulation?.map?.((item, i) => (
        item.Key !== null && item.Value !== null &&
          <InfoRow
            id={`employment${index}AccumulationRow${i}`}
            key={`employment${index}AccumulationRow${i}`}
            label={translateKey(item.Key)}
            value={valueWithUnit(item.Value, item.Unit)}
          />
      ))}
    </Expandable>
  )

  if (account === null) {
    return (
      <div className='UserInfoEmployments' />
    )
  }

  return (
    <div className='UserInfoEmployments'>
      <InfoRow
        variant='title'
        label={t('ownInformation.employments')}
      />
      {employments?.map((employment, i) =>
        employmentSection(employment, i)
      )}
    </div>
  )
}
export default UserInfoEmployments
