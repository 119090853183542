import apiConfig from '../config/apiConfig'
import authProviderConfig, { authProviderDemoConfig } from '../config/authProviderConfig'
import registrationMethods from '../config/registrationMethods'
import { useContext } from 'react'
import { LocaleContext } from '../contexts/LocaleContext'

type authMethod = typeof registrationMethods[number]

interface ReturnProps {
  linkToAuthMethod: (method: authMethod, demo?: boolean) => string
}

// Signicat methods
const acrValues = {
  MOBIILIVARMENNE: 'ftn_idp:fi-telia',
  AKTIA: 'ftn_idp:fi-aktia',
  ALANDSBANKEN: 'ftn_idp:fi-alandsbanken',
  DANSKEBANK: 'ftn_idp:fi-danskebank',
  HANDELSBANKEN: 'ftn_idp:fi-handelsbanken',
  NORDEA: 'ftn_idp:fi-nordea',
  POPPANKKI: 'ftn_idp:fi-pop',
  SAASTOPANKKI: 'ftn_idp:fi-saastopankki',
  SPANKKI: 'ftn_idp:fi-spankki',
  OMASAASTOPANKKI: 'ftn_idp:fi-omasp',
  OSUUSPANKKI: 'ftn_idp:fi-op'
}

const useAuthProvider = (): ReturnProps => {
  const { locale } = useContext(LocaleContext)

  const linkToAuthMethod = (method: authMethod, demo?: boolean): string => {
    if (
      apiConfig.useMockApi ||
      authProviderConfig?.authorizeUri === undefined ||
      authProviderConfig?.authorizeUri === 'test'
    ) {
      return `/redirect?code=xxx&state=${demo === true ? 'demo' : 'xxx'}`
    }

    const state = demo === true ? authProviderDemoConfig.state : authProviderConfig.state
    const clientId = demo === true ? authProviderDemoConfig.clientId : authProviderConfig.clientId
    const uri = demo === true ? authProviderDemoConfig.authorizeUri : authProviderConfig.authorizeUri

    const params = {
      response_type: 'code',
      scope: 'openid+profile+nin+ftn-extra',
      client_id: clientId,
      state,
      redirect_uri: authProviderConfig?.redirectUri ?? '',
      acr_values: acrValues[method],
      ui_locales: locale,
      prompt: 'login'
    }

    return `${uri}?${new URLSearchParams(params).toString().replaceAll('%2B', '+')}`
  }

  return {
    linkToAuthMethod
  }
}

export default useAuthProvider
