import { ReactElement } from 'react'
import Button from 'react-bootstrap/Button'
import useAnalytics from '../hooks/useAnalytics'
import { Alert } from 'react-bootstrap'
import useLocalization from '../hooks/useLocalization'

const linkUrl = 'https://policies.google.com/technologies/cookies'

interface Props {
  onClose: () => void
}

const CookieConsentDialog = ({ onClose }: Props): ReactElement => {
  const { t } = useLocalization()
  const { setCookieConsent } = useAnalytics()

  const handleReject = (): void => {
    setCookieConsent(false)
    onClose()
  }

  const handleAccept = (): void => {
    setCookieConsent(true)
    onClose()
  }

  const formatLink = (body: string): ReactElement[] => {
    const matches = body.match(/%.*%/)
    const link = Array.isArray(matches) ? matches[0] : ''
    const parts = body.split(/%.*%/)
    return parts.map((p, i) => {
      if (i < parts.length - 1) {
        return (
          <span key={`bodyRowPart${i}`}>
            {p}
            <a rel='noreferrer' href={linkUrl} target='_blank'>{link.replaceAll('%', '')}</a>
          </span>
        )
      } else {
        return <span key={`bodyRowPart${i}`}>{p}</span>
      }
    })
  }

  const formatBody = (body: string): ReactElement[] => {
    const rows = body.split('\n')
    return rows.map((r, i) => <span key={`bodyRow${i}`}>{formatLink(r)}<br /></span>)
  }

  return (
    <div className='CookieConsentDialog'>
      <Alert variant='light'>
        <Alert.Heading>
          {t('cookieConsentDialog.title')}
        </Alert.Heading>
        <p>
          {formatBody(t('cookieConsentDialog.body'))}
        </p>
        <hr />
        <div className='actions'>
          <Button variant='secondary' onClick={handleReject}>
            {t('cookieConsentDialog.reject')}
          </Button>
          <Button onClick={handleAccept}>
            {t('cookieConsentDialog.accept')}
          </Button>
        </div>
      </Alert>
    </div>
  )
}

export default CookieConsentDialog
