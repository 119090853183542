import { ReactElement } from 'react'
import Absence from '../types/Absence'
import useFormatter from '../hooks/useFormatter'
import useAccount from '../hooks/useAccount'
import useLocalization from '../hooks/useLocalization'

interface Props {
  id?: string
  absence: Absence
}

const AbsenceRow = ({ id, absence }: Props): ReactElement => {
  const { t } = useLocalization()
  const { companyName } = useAccount()
  const { formatDate } = useFormatter()

  const timePeriodLabel = absence.StartDate !== absence.EndDate
    ? `${formatDate(absence.StartDate, 'dateOnly')} - ${formatDate(absence.EndDate, 'dateOnly')}`
    : `${formatDate(absence.StartDate, 'dateOnly')}`

  return (
    <div className='AbsenceRow' id={id}>
      {companyName(absence.CompanyId) !== null && (
        <div className='row'>
          <div className='column'>
            <span className='label'>{t('medicalCertifications.employment')}: </span>
            <span className='value companyName'>{companyName(absence.CompanyId)}</span>
          </div>
        </div>
      )}
      <div className='row'>
        <div className='column period'>
          <span className='label'>{t('medicalCertifications.period')}: </span>
          <span className='value'>{timePeriodLabel}</span>
        </div>
        <div className='column sentAt'>
          <span className='label'>{t('medicalCertifications.sentAt')}: </span>
          <span className='value'>{formatDate(absence.Timestamp, 'dateOnly')}</span>
        </div>
      </div>
    </div>
  )
}

export default AbsenceRow
