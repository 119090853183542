import React, { ReactElement, useContext } from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import DatePicker from 'react-date-picker'
import { LocaleContext } from '../contexts/LocaleContext'
import { MdCalendarToday } from 'react-icons/md'

interface Props {
  error: string | null
  name: string
  label: string
  onChange: (name: string, value: Date | null) => void
  value: Date | null
  minDate?: Date | null
  maxDate?: Date | null
  id?: string
}

const FormDatePicker = ({ id, name, value, label, onChange, minDate, maxDate, error }: Props): ReactElement => {
  const { locale } = useContext(LocaleContext)

  const handleChange = (value: Date | Array<Date | null> | null): void => {
    onChange(name, Array.isArray(value) ? null : value)
  }

  return (
    <Row
      id={id}
      className={['FormDatePicker', error !== null ? 'error' : null].filter(i => i).join(' ')}
    >
      <Form.Label column xs={5}>
        {label}
      </Form.Label>
      <Col xs={7}>
        <DatePicker
          calendarIcon={<MdCalendarToday />}
          openCalendarOnFocus={false}
          onChange={handleChange}
          value={value}
          name={name}
          minDate={minDate ?? undefined}
          maxDate={maxDate ?? undefined}
          locale={locale}
        />
        {error !== null && <span className='errorMessage'>{error}</span>}
      </Col>
    </Row>
  )
}
export default FormDatePicker
