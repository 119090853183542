import React, { ReactElement, useContext } from 'react'
import { Link } from 'react-router-dom'
import useLocalization from '../hooks/useLocalization'
import { AuthContext } from '../contexts/AuthContext'
import Button from 'react-bootstrap/Button'

interface Props {
  onCookieInfoClick: () => void
}

const Footer = ({ onCookieInfoClick }: Props): ReactElement => {
  const { isAuth, termsAgreed } = useContext(AuthContext)
  const { t } = useLocalization()

  return (
    <div className='Footer'>
      <a rel='noreferrer' target='_blank' href='https://www.silta.fi/tietosuojaseloste-simo'>
        {t('other.privacyStatement')}
      </a>
      <span className='separator'>|</span>
      <Button variant='link' onClick={onCookieInfoClick}>{t('other.cookiePolicy')}</Button>
      <span className='separator'>|</span>
      {isAuth && termsAgreed && (
        <>
          <Link to='/terms'>{t('other.terms')}</Link>
          <span className='separator'>|</span>
        </>
      )}
      &copy; Silta Oy {new Date().getFullYear()}
    </div>
  )
}
export default Footer
