import React, { ReactElement, useEffect } from 'react'
import useAccount from '../hooks/useAccount'
import Loader from '../components/Loader'
import useLocalization from '../hooks/useLocalization'
import TaxInfoPercentages from '../components/TaxInfoPercentages'
import PageTitle from '../components/PageTitle'
import Notification from '../components/Notification'
import useAnalytics from '../hooks/useAnalytics'
import Icon from '../components/Icon'
import TaxInfoLimits from '../components/TaxInfoLimits'

const TaxInfo = (): ReactElement => {
  const { logPageView } = useAnalytics()
  const { t } = useLocalization()
  const { loading, taxCard, getAccount, error } = useAccount()

  useEffect(() => {
    logPageView('Tax card')
    void getAccount()
    // eslint-disable-next-line
  }, [])

  if (loading) {
    return <Loader />
  }

  return (
    <div className='TaxInfo'>
      <PageTitle icon={<Icon route='taxInfo' />}>{t('taxInformation.title')}</PageTitle>
      <div className='contentContainer'>
        <div className='contentColumn'>
          {error !== null && <Notification variant='danger'>{error}</Notification>}
          {error === null && <TaxInfoPercentages taxCard={taxCard} />}
        </div>
        <div className='contentColumn'>
          {error === null && <TaxInfoLimits taxCard={taxCard} />}
        </div>
      </div>
    </div>
  )
}
export default TaxInfo
